import axios from 'axios';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import { baseUrl } from '../../../service/baseUrl';
// global
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// company
import { useCompanyState } from '../CompanyMaster/ProviderCompany';
// component
import {
  useUserCompanyDispatch,
  useUserCompanyState,
} from './ProviderUserCompany';
import { getAllUserCompany, dispatchWithTimeout } from './ActionDispatch';

import RenderForm from '../../../components/RenderForm/RenderForm';

const BodyModalEdit = () => {
  const [validated, setValidated] = useState(false);
  const [error] = useState(null);
  // global state
  const globalState = useCSState();
  const CSDispatch = useCSDispatch();
  const key = globalState.key;
  // company state
  const CompanyState = useCompanyState();
  const stateCompanies = CompanyState.data;
  // component state
  const dispatch = useUserCompanyDispatch();
  const state = useUserCompanyState();
  const stateValue = state.valueEdit;

  const listColumn = [
    {
      id: 1,
      label: 'User ID',
      value: 'userId',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 2,
      label: 'User Name',
      value: 'userName',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 3,
      label: 'Company Name',
      value: 'company',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 4,
      label: 'Company ID',
      value: 'companyId',
      type: 'select-companies',
      required: true,
      technology: [...stateCompanies],
      funcCallbackTech: (e) => {
        console.log(e, 'select edit');
        const copyObj = Object.assign({}, stateValue, {
          companyId: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_EDIT_${key}`,
          payload: copyObj,
        });
      },
    },
  ];

  const setFormValue = (e, index, column) => {
    // Create a copy of the columns
    const copyObj = Object.assign({}, stateValue, { [column]: e.target.value });

    dispatch({
      type: `SET_VALUE_EDIT_${key}`,
      payload: copyObj,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        for (const key in stateValue) {
          const value = stateValue[key];
          if (value !== undefined) {
            formData[key] = value;
          }
        }

        const url = `user/user-company/${stateValue.phoneNumber}`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios({
          method: 'patch',
          url: `${baseUrl}/${url}`,
          data: {
            companyId: formData.companyId,
          },
          headers: { Authorization: decSecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 201) {
          dispatch(getAllUserCompany(dispatch));
          dispatchWithTimeout(dispatch, `SET_MODAL_EDIT_${key}`, false, 500);
          dispatchWithTimeout(CSDispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        CSDispatch({ type: `SET_MODAL_FAILED`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <section>
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
      >
        {listColumn.map((column, index) => {
          const inputValue = stateValue[column.value] || '';
          return (
            <RenderForm
              key={column.value}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              error={error}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Save</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};
export default BodyModalEdit;
