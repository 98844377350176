import axios from "axios";
import React from "react";
import { ButtonCompNew } from "../../../components/button/ButtonComp";
import { baseUrl } from "../../../service/baseUrl";
import {
  dispatchWithTimeout,
  getAllProduct,
  getAllSubProduct,
} from "../ProductDashboard/ActionDispatch";
import {
  useProductDispatch,
  useProductState,
} from "../ProductDashboard/ProviderProduct";

const BodyModalDelete = () => {
  const approle = localStorage.getItem('approle');
  const state = useProductState();
  const dispatch = useProductDispatch();
  const stateValue = state.valueEditTech;

  const handleDelete = async () => {
    const url = `v1/technology/technlogy-master/${stateValue.id}`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);

    const response = await axios
      .delete(`${baseUrl}/${url}`, {
        headers: { Authorization: decSecret },
      })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e.response;
      });

    if (response.status === 200) {
      const options = approle ?? null;
      dispatch(getAllSubProduct(dispatch, options));
      dispatch(getAllProduct(dispatch, options));
      dispatch(getAllSubProduct(dispatch));
      dispatchWithTimeout(dispatch, "SET_MODAL_DELETE_TECHNOLOGY", false, 1000);
      dispatchWithTimeout(dispatch, "SET_MODAL_SUCCESS", true, 1000);
      return;
    }
    dispatch({ type: "SET_MODAL_FAILED", payload: true });
    return;
  };

  return (
    <div className="button-on-form">
      <ButtonCompNew onClick={() => handleDelete()} variant={"third"}>
        Delete
      </ButtonCompNew>
    </div>
  );
};

export default BodyModalDelete;
