export const checkImageDimension = async (file, setError, dispatch) => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', event => {
        const _loadedImageUrl = event.target?.result
        const image = document.createElement('img')
        image.src = _loadedImageUrl

        image.addEventListener('load', () => {
          const { width, height } = image

          if (width > 500 || height > 500) {
            setError('Image tidak melebihi 500 pixel X 500 pixel')
            dispatch({ type: 'SET_MODAL_FAILED_MSG', payload: 'image tidak boleh melebihi 500 pixel x 500 pixel' });
            dispatch({ type: 'SET_MODAL_FAILED', payload: true });
            resolve(false)
          }else{
            setError(false)
            resolve(true)
          }
        })
      })
    })
  }