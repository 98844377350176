import { createContext, useReducer, useContext } from 'react';

// create context
const SalesUsersStateContext = createContext();
const SalesUsersDispatchContext = createContext();
// state names
const stateGroup = 'SALESUSERS'
// Create a reducer function
const SalesUsersReducer = (state, action) => {
  switch (action.type) {
    case `SET_DATA_${stateGroup}`:
      return { ...state, data: action.payload };
    case `SET_LOADING_${stateGroup}`:
      return { ...state, loading: action.payload };
    case `SET_MODAL_EDIT_${stateGroup}`:
      return { ...state, showModalEdit: action.payload };
    case `SET_VALUE_EDIT_${stateGroup}`:
      return { ...state, valueEdit: action.payload };
    default:
      return state;
  }
};

// Create a provider component
const SalesUsersProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalEdit: false,
    valueEdit: {},
  };
  const [state, dispatch] = useReducer(SalesUsersReducer, initialState);

  return (
    <SalesUsersStateContext.Provider value={state}>
      <SalesUsersDispatchContext.Provider value={dispatch}>
        {children}
      </SalesUsersDispatchContext.Provider>
    </SalesUsersStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useSalesUsersState = () => {
  const context = useContext(SalesUsersStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useSalesUsersDispatch = () => {
  const context = useContext(SalesUsersDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { SalesUsersProvider, useSalesUsersState, useSalesUsersDispatch };
