import { Formik } from 'formik';
import { React, useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { AiFillEye } from "react-icons/ai";
import * as yup from 'yup';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ButtonComp from '../../components/button/ButtonComp';
import './login-page.scss';
import { useNavigate } from 'react-router-dom';
import { userAdminCS } from './dummy';

let validationSchema = yup.object({
  username: yup.string().required('Tidak boleh kosong'),
  password: yup.string().required('Tidak boleh kosong'),
})

const LoginAdminCSPage = () => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('loggedAdminCS');
  const {setError, register, reset, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema)
  })

  const handleRegister = async (values) => {
    const adminData = userAdminCS.filter(function (v) {
      return v.username === values.username;
    });
    if (!adminData[0] || adminData[0]?.password !== values.password) {
      alert('Username or password not valid');
    }
    if (adminData[0]?.password === values.password) {
      localStorage.setItem('loggedAdminCS', true);
      const dataUser = JSON.stringify([
        { username: values?.username, role: adminData[0].role },
      ]);
      localStorage.setItem('userData', dataUser);
      setTimeout(() => {
        navigate('/cs-dashboard');
      }, 1500);
      // localStorage.setItem("salesid", salesData[0].salesId);
    }
  };

  const [intip, setIntip] = useState({
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setIntip({
        ...intip,
        showPassword: !intip.showPassword,
    });
  };
  const handlePasswordChange = (prop) => (event) => {
    setIntip({
        ...intip,
        [prop]: event.target.value,
    });
    // setFormValue(event, index, column.value)
  } 

  useEffect(() => {
    if (isLogin) {
      navigate('/cs-dashboard');
    }
  }, []);

  return (
    <div className="LoginPage">
      <div className="container">
          <article className="card-body mx-auto py-5 card bg-light" style={{maxWidth:'400px'}}>
            <h4 className="card-title text-center pb-3">Login Admin</h4>
            <Form onSubmit={handleSubmit(handleRegister)}>
              <Form.Group className="form-group">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Username"
                  name='username'
                  id='username'
                  {...register('username')}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.username?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={
                    intip.showPassword
                      ? "text"
                      : "password"
                  }
                  placeholder="Password"
                  name='password'
                  id='password'                      
                  {...register('password')}
                  isInvalid={!!errors.password}
                  value={intip.password}
                  onChange={handlePasswordChange("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Check 
                type="switch"
                id="custom-switch"
                label="Show password"
                className='pb-1'
                onClick={handleClickShowPassword}
              />
              <div className='text-end'>
                <ButtonComp type={'submit'} primary>Log in</ButtonComp>
              </div>
            </Form>
          </article>
      </div>
    </div>
  );
};

export default LoginAdminCSPage;
