import { useEffect, useState } from 'react';
// global states
import { useITState } from '../ITDashboard/ProviderIT';
// rubah jadi provider dan dispatch local table yang dibutuhkan, dari import sampai nama function yang dibutuhkan
import { usePhoneNumberListState, usePhoneNumberListDispatch } from './ProviderPhoneNumberListUsers';
import { getPhoneNumberList } from './PhoneNumberListDispatch';
import { BiEdit } from "react-icons/bi";

import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import ModalComp from '../../../components/modal/ModalComp';
import BodyModalEdit from './BodyModalEdit';
import ButtonComp from '../../../components/button/ButtonComp';
import BodyModalCreate from './BodyModalCreate';

// rubah nama component jadi nama table yang dibutuhkan
const PhoneNumberList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // global state
  const globalState = useITState();
  const key = globalState.key;
  // rubah nama function yang sudah di ganti di provider dan dispatch di atas
  const state = usePhoneNumberListState();
  const dispatch = usePhoneNumberListDispatch();
  const loading = state.loading;
  const data = state.data;

  const fetchData = async () => {
    dispatch(getPhoneNumberList(dispatch));
  };

  const tableColumn = [
    {
      value: 'label',
      label: 'label',
      type: 'string',
      search: true,
    },
    {
      value: 'phoneNumber',
      label: 'phonenumber',
      type: 'string',
      search: true,
    },
    {
      value: 'action',
      label: 'Action',
      type: 'button',
      search: false,
      renderButton: [
        {
          type: 'edit',
          name: [<BiEdit />],
          handler: (e) => {
            dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_EDIT_${key}`, payload: e });
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <>
      <div className="text-end">
        <ButtonComp
          clickFunc={() => dispatch({ type: `SET_MODAL_CREATE_PHONENUMBERLIST`, payload: true })}
          buttonType={'button'}
          primary
        >
          + Phone Number
        </ButtonComp>
      </div>
      <div>
        <div className="search_wrap mt-4 py-2">
          <input
            type="text"
            placeholder="Search Here"
            required
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>

        <MainTableCustom
          header={tableColumn}
          data={data}
          loading={loading}
          searchTerm={searchTerm}
        />
      <ModalComp
        show={state.showModalCreate}
        onHide={() =>
          dispatch({ type: `SET_MODAL_CREATE_PHONENUMBERLIST`, payload: false })
        }
        ModalHeader={'Phone Number Data'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalCreate />
      </ModalComp>
        <ModalComp
          show={state.showModalEdit}
          onHide={() =>
            dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: false })
          }
          ModalHeader={'AllUsers Data:'}
          buttonText={{ primary: 'Create' }}
        >
          <BodyModalEdit />
        </ModalComp>
      </div>
    </>
  );
};

export default PhoneNumberList;
