import React from 'react';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import ButtonComp from '../../../components/button/ButtonComp';
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
import { useMachineDispatch, useMachineState } from './ProviderMachine';
import {
  getAllMachines,
  dispatchWithTimeout,
} from '../MachineMaster/ActionDispatch';

const BodyModalDelete = ({ value }) => {
  const globalState = useCSState();
  const globaldispatch = useCSDispatch();
  const dispatch = useMachineDispatch();
  const state = useMachineState();
  const key = globalState.key;
  const stateValue = state.valueDelete;

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const url = `machines/`;
      const rahasia = process.env.REACT_APP_RAHASIA;
      const decSecret = window.btoa(rahasia);
      const response = await axios({
        method: 'delete',
        url: `${baseUrl}/${url}`,
        data: {
          companyId: stateValue.companyId,
          unitId: stateValue.unitId,
        },
        headers: { Authorization: decSecret },
      })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e.response;
        });
      if (response.status === 201) {
        dispatch(getAllMachines(dispatch));
        dispatchWithTimeout(dispatch, `SET_MODAL_DELETE_${key}`, false, 500);
        dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
        return;
      }
      globaldispatch({ type: `SET_MODAL_FAILED`, payload: true });
      return;
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <>
      <div className='d-flex flex-column'>
        <span><strong>Unit ID: </strong>{value.unitId}</span>
        <span><strong>Machine name: </strong>{value.machineName}</span>
        <span><strong>Company ID: </strong>{value.companyId}</span>
      </div>
      <div className='pt-5'>
        <ButtonComp clickFunc={handleDelete} buttonType={'button'} third>
          Delete
        </ButtonComp>
      </div>

    </>
  );
};

export default BodyModalDelete;
