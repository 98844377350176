import React, { useEffect, useState } from 'react';
import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import {
  useProductDispatch,
  useProductState,
} from '../ProductDashboard/ProviderProduct';
import { dispatchWithTimeout, getAllSubProduct, getAllSubProductHidden } from '../ProductDashboard/ActionDispatch';
import ModalComp from '../../../components/modal/ModalComp';
import BodyModalCreate from './BodyModalCreate';
import BodyModalEdit from './BodyModalEdit';
import BodyModalDelete from './BodyModalDelete';
import Form from 'react-bootstrap/Form';
import { BiEdit, BiTrash } from "react-icons/bi";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import axios from "axios";
import { baseUrl } from "../../../service/baseUrl";


const ProductMaster = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [techIdFilter, setTechIdFilter] = useState(0);
  const [display, setDisplay] = useState(true)
  const state = useProductState();
  const dispatch = useProductDispatch();
  const dataProduct = state.dataSubProduct;
  const stateTechnology = state.dataProduct ? [...state.dataProduct] : [];
  const [filteredProduct, setFilteredProduct] = useState();
  const approle = localStorage.getItem('approle');
  const dataFiltered = (id, whichData) => {
    const result = whichData.filter((data) => {
      return data.technologyId === parseInt(id);
    });
    return result;
  };
  const fetchDataSub = async () => {
    const options = approle ?? null;
    await dispatch(getAllSubProduct(dispatch, options));
    await dispatch(getAllSubProductHidden(dispatch, options));
  };
  const loading = state.loadingSubProduct;
  const handleUpdateSort = async (id1, id2) => {
    const data = {
      id1, id2
    }
    const url = `v1/technology/technlogy-subproduct-master/updateurutan`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .post(`${baseUrl}/${url}`, data, {
        headers: { Authorization: decSecret },
      })
      .then((response) => {
        fetchDataSub();
        return response;
      })
      .catch((e) => {
        return e.response;
      });
    if (response.status === 200) {
      setFilteredProduct();
      dispatchWithTimeout(
        dispatch,
        "SET_MODAL_EDIT_TECHNOLOGY",
        false,
        500
      );
      return;
    }
    dispatch({ type: "SET_MODAL_FAILED", payload: true });
    return;
  }
  const handleUpdateHidden = async (id) => {
    const data = {hidden: true, id};
    const url = `v1/technology/technlogy-subproduct-master/hidden`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .post(`${baseUrl}/${url}`, data, {
        headers: { Authorization: decSecret },
      })
      .then((response) => {
        fetchDataSub();
        return response;
      })
      .catch((e) => {
        return e.response;
      });
    if (response.status === 200) {
      setFilteredProduct();
      dispatchWithTimeout(
        dispatch,
        "SET_MODAL_EDIT_TECHNOLOGY",
        false,
        500
      );
      return;
    }
    dispatch({ type: "SET_MODAL_FAILED", payload: true });
    return;
  }
  const tableColumn = [
    {
      id: 1,
      value: 'nameProduct',
      label: 'Name Product',
      type: 'string',
      search: true,
    },
    {
      id: 2,
      value: 'imageProduct',
      label: 'Image Product',
      type: 'image',
    },
    {
      id: 3,
      value: 'hidden',
      valueDisplay: display,
      label: 'Display',
      type: 'display',
      handler: (id) => {
        console.log(display, id)
        setDisplay(false);
        handleUpdateHidden(id)
      },
    },
    {
      id: 4,
      value: 'action',
      label: 'Action',
      type: 'button',
      search: false,
      renderButton: [
        {
          type: 'edit',
          name: [<BiEdit />],
          handler: (e) => {
            dispatch({ type: 'SET_MODAL_EDIT_PRODUCT', payload: true });
            dispatch({ type: 'SET_VALUE_EDIT_PRODUCT', payload: e });
          },
        },
        {
          type: 'hapus',
          name: [<BiTrash />],
          handler: (e) => {
            dispatch({ type: 'SET_MODAL_DELETE_PRODUCT', payload: true });
            dispatch({ type: 'SET_VALUE_EDIT_PRODUCT', payload: e });
          },
        },
      ],
    },
    {
      id: 4,
      value: "sortDisplay",
      show: filteredProduct ? true : false,
      label: "Sort",
      type: "sort",
      renderButton: [
        {
          type: "up",
          name: [<FaArrowAltCircleUp />],
          handler: (current, prevRow, nextRow) => {
            const prevId = prevRow.id;
            const currentId = current.id;
            handleUpdateSort(currentId, prevId)
          },
        },
        {
          type: "down",
          name: [<FaArrowAltCircleDown />],
          handler: (current, prevRow, nextRow) => {
            const currentId = current.id;
            const nextId = nextRow.id;
            handleUpdateSort(currentId, nextId)
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchDataSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="search_wrap mt-4">
        <input
          type="text"
          placeholder="Search Here"
          required
          onChange={(e) => {
            setFilteredProduct();
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          className='py-2'
        />
      </div>
      <div className='pb-2'>
        <Form.Select
          onChange={(e) => {
            if (parseInt(e.target.value) === 0) {
              setFilteredProduct();
            } else {
              setTechIdFilter(e.target.value)
              const filtered = dataFiltered(e.target.value, dataProduct);
              setFilteredProduct(filtered);
            }
          }}
          aria-label="Default select example"
        >
          <option value="0">Filter product by technology</option>
          {stateTechnology.map((v, idx) => {
            return <option value={v.id}>{v.technologyName}</option>;
          })}
        </Form.Select>
      </div>
      <MainTableCustom
        header={tableColumn}
        data={filteredProduct ? filteredProduct : dataProduct}
        loading={loading}
        searchTerm={searchTerm}
      />
      <ModalComp
        show={state.showModalCreateSubProduct}
        onHide={() =>
          dispatch({ type: 'SET_MODAL_CREATE_PRODUCT', payload: false })
        }
        ModalHeader={'Product data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalCreate />
      </ModalComp>
      <ModalComp
        show={state.showModalEditSubProduct}
        onHide={() =>
          dispatch({ type: 'SET_MODAL_EDIT_PRODUCT', payload: false })
        }
        ModalHeader={'Product data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalEdit />
      </ModalComp>

      <ModalComp
        show={state.showModalDeleteProduct}
        ModalHeader={`Confirm Delete: ${state.valueEditProduct?.nameProduct}`}
        onHide={() =>
          dispatch({ type: 'SET_MODAL_DELETE_PRODUCT', payload: false })
        }
      >
        <BodyModalDelete />
      </ModalComp>
    </div>
  );
};

export default ProductMaster;
