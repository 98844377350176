import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
// global states
import { useITDispatch } from '../ITDashboard/ProviderIT';
// ticket states
import { useAllUsersState, useAllUsersDispatch } from './ProviderAllUsers';
import { dispatchWithTimeout, getAllAllUsers } from './ActionDispatch';
import RenderForm from '../../../components/RenderForm/RenderForm';
import { selectAreas, selectRoles } from '../../../utils/listRole';

const BodyModalCreate = () => {
  const [imagePreview] = useState(null);
  const [error] = useState(null);
  const [validated, setValidated] = useState(false);
  // global state
  // const globalState = useITState();
  const globaldispatch = useITDispatch();
  // const key = globalState.key;
  // component state
  const dispatch = useAllUsersDispatch();
  const state = useAllUsersState();
  const createValue = state.valueCreate;
  const listColumn = [
    {
      id: 1,
      label: '* Name',
      value: 'fullname',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'username',
      value: 'userName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'password',
      value: 'password',
      type: 'password',
      required: true,
      readOnly: false,
    },
    {
      id: 4,
      label: 'role',
      value: 'role',
      type: 'select',
      defaultSelect: createValue.role,
      selectOption: selectRoles,
      funcCallback: (e) => {
        const copyObj = Object.assign({}, createValue, {
          role: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_CREATE_ALLUSERS`,
          payload: copyObj,
        });
      },
      required: false,
      readOnly: false,
    },
    {
      id: 5,
      label: 'area',
      value: 'area',
      type: 'select',
      defaultSelect: createValue.area,
      selectOption: selectAreas,
      funcCallback: (e) => {
        const copyObj = Object.assign({}, createValue, {
          area: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_CREATE_ALLUSERS`,
          payload: copyObj,
        });
      },
      required: false,
      readOnly: false,
    },
    {
      id: 6,
      label: 'email',
      value: 'email',
      type: 'text',
      required: true,
      readOnly: false,
    },
  ];

  const setFormValue = (e, index, column) => {
    const copyObj = Object.assign({}, createValue, {
      [column]: e.target.value,
    });
    dispatch({
      type: `SET_VALUE_CREATE_ALLUSERS`,
      payload: copyObj,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    try {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        for (const kunci in createValue) {
          const value = createValue[kunci];
          if (value !== undefined) {
            formData[kunci] = value;
          }
        }

        const url = `userapp`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decsecret = window.btoa(rahasia);
        const response = await axios({
          method: 'post',
          url: `${baseUrl}/${url}`,
          data: {...formData
          },
          headers: { Authorization: decsecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });

        if (response.status === 200) {
          dispatch(getAllAllUsers(dispatch));
          dispatch({
            type: `SET_VALUE_CREATE_ALLUSERS`,
            payload: {},
          });
          dispatchWithTimeout(
            globaldispatch,
            `SET_MODAL_CREATE_ALLUSERS`,
            false,
            500
          );
          dispatchWithTimeout(dispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        const errorMsg =
          response.data.message === 'user created'
            ? 'User ID already exists'
            : '';
        dispatch({
          type: `SET_VALUE_CREATE_ALLUSERS`,
          payload: {},
        });
        globaldispatch({
          type: `SET_FAILED_MESSAGE`,
          payload: errorMsg,
        });
        globaldispatch({ type: `SET_MODAL_FAILED`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="form-container px-2 px-4"
      >
        {listColumn.map((column, index) => {
          const inputValue = createValue[column.value] || '';
          return (
            <RenderForm
              keyValue={column.id}
              key={column.id}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              errorFile={error}
              imagePreview={imagePreview}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Create</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};

export default BodyModalCreate;
