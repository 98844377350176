import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import ButtonComp from '../../../components/button/ButtonComp';
import ProductMaster from '../ProductMaster/ProductMaster';
import TechnologyMaster from '../TechnologyMaster/TechnologyMaster';
import { useProductDispatch, useProductState } from './ProviderProduct';
import ModalComp from '../../../components/modal/ModalComp';
// edit account detail import
import { useITDispatch } from '../../AdminIT/ITDashboard/ProviderIT';
import AccountDetailModal from '../../../components/AccountDetailModal/AccountDetailModal';
import { CMSDashboardAllowed, userRoles } from '../../../utils/listRole';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ProductHidden from '../ProductHidden/ProductHidden';


const ProductDashboard = () => {
  const isLogin = localStorage.getItem('loggedAdmin');
  const userData = JSON.parse(localStorage.getItem('userData'));
  const dispatch = useProductDispatch();
  const state = useProductState();
  // IT context for edit account detail
  const itDispatch = useITDispatch();
  const navigate = useNavigate();
  const userRole = localStorage.getItem('role');
  const userId = localStorage.getItem('userID');
  const failedMsgState = state.setModalFailedMsg;

  const [key, setKey] = useState('technology');

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  const activeTab = (k) => {
    setKey(k);
  };
  const handleShowCreateModal = () => {
    if (key === 'technology') {
      dispatch({ type: 'SET_MODAL_CREATE_TECHNOLOGY', payload: true });
    }
    if (key === 'product') {
      dispatch({ type: 'SET_MODAL_CREATE_PRODUCT', payload: true });
    }
  };
  // for show edit account detail
  const handleShowAccountModal = (what) => {
    itDispatch({ type: `SET_MODAL_ACCOUNT_IT`, payload: true });
  };
  const fetchUser = async () => {
    const url = `userapp/getonewhere`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decsecret = window.btoa(rahasia);
    const response = await axios({
      method: 'post',
      url: `${baseUrl}/${url}`,
      data: {
        where: {
          userID: userId,
        },
      },
      headers: { Authorization: decsecret },
    })
      .then((response) => {
        if (response.data.data) {
          if (
            response.data.data.role === userRoles.role1 ||
            response.data.data.role === userRoles.role2
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
      .catch((e) => {
        return e.response;
      });
    return response;
  };
  useEffect(() => {
    async function fetch() {
      const userStatus = await fetchUser();
      return userStatus;
    }
    fetch()
      .then((result) => {
        if (result) {
          const isAllowed = CMSDashboardAllowed.includes(userRole);
          if (!isAllowed) {
            navigate('/');
          }
          return;
        } else {
          localStorage.clear();
          setTimeout(() => {
            navigate('/');
          }, 500);
        }
      })
      .catch((e) => {
        return;
      });
  }, []);

  return (
    <div className="ProductDashboard">
        <div className="sales-container d-flex align-items-center justify-content-between">
          <div>
            <p>Login User: {userData[0]?.fullname} </p>
            <p>Role : {userData[0]?.approle ?? userData[0]?.role} </p>
          </div>
          <DropdownButton variant='light' title="Setting">
            <Dropdown.Item onClick={() => handleShowAccountModal('IT')}>My account</Dropdown.Item>
            {key !== 'producthidden' && <Dropdown.Item onClick={() => handleShowCreateModal()}>Create {key}</Dropdown.Item>}
            <Dropdown.Item onClick={() => handleLogout()}>Log out</Dropdown.Item>
          </DropdownButton>          
        </div>

      <Tabs
        activeKey={key}
        onSelect={(k) => activeTab(k)}
        fill
        justify
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="technology" title="Technology">
          <TechnologyMaster />
        </Tab>
        <Tab eventKey="product" title="Product">
          <ProductMaster />
        </Tab>
        <Tab eventKey="producthidden" title="Hidden">
          <ProductHidden />
        </Tab>
        {/* <Tab eventKey="history" title="History">
          Tab content for History
        </Tab> */}
      </Tabs>
      <ModalComp
        show={state.showModalFailed}
        onHide={() => dispatch({ type: 'SET_MODAL_FAILED', payload: false })}
        ModalHeader={'sorry'}
        buttonText={{ primary: 'close' }}
      >
        {failedMsgState}
      </ModalComp>
      <ModalComp
        onHide={() => dispatch({ type: 'SET_MODAL_SUCCESS', payload: false })}
        show={state.showModalSuccess}
        ModalHeader={'updated!'}
        buttonText={{ primary: 'close' }}
      >
        successfully updated!
      </ModalComp>
      <AccountDetailModal
        ModalHeader={'My Account'}
        buttonText={{ primary: 'update' }}
      />
    </div>
  );
};

export default ProductDashboard;
