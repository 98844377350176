import React from "react";
import { Table } from "react-bootstrap";
import TableHeadComp from "./TableHeadComp";
import "./table-style.scss";
import TableBodyComp from "./TableBodyComp";

const MainTableCustom = ({ header, data, loading, searchTerm }) => {
  return (
    <div className="table__style">
      <Table striped bordered hover>
        <TableHeadComp headers={header} />
        <TableBodyComp
          headers={header}
          data={data}
          loading={loading}
          searchTerm={searchTerm}
        />
      </Table>
    </div>
  );
};

export default MainTableCustom;
