export const userRoles = {
    role1: "adminit",
    role2: "admincms",
    role3: "sales",
};

export const SalesAppRole2 = {
    approle1: 'sales',
    approle2: 'management'
}
export const CMSAppRole3 = {
    approle1: 'coding',
    approle2: 'packaging'
}
export const userRolesData = {
    adminit: {
        label: "IT",
        key: userRoles.role1, 
        value: userRoles.role1,
        subRole: [],
        homepage: 'it-dashboard',
    },
    admincms: {
        label: "CMS",
        key: userRoles.role2,
        value: userRoles.role2,
        subRole: [],
        homepage: "product-dashboard",
    },
    sales: {
        label: "SALES",
        key: userRoles.role3,
        value: userRoles.role3,
        subRole: [
            {
                0: "sales"
            },
            {
                1: "management"
            }
        ],
        homepage: 'sales',
    },
}
export const selectRoles = [
    { key: userRoles.role1, value: userRoles.role1 },
    { key: userRoles.role2, value: userRoles.role2 },
    { key: userRoles.role3, value: userRoles.role3 },
]

export const selectRolesSalesApp = [
    { key: SalesAppRole2.approle1, value: SalesAppRole2.approle1 },
    { key: SalesAppRole2.approle2, value: SalesAppRole2.approle2 },
]
export const selectRolesCMSApp = [
    { key: CMSAppRole3.approle1, value: CMSAppRole3.approle1 },
    { key: CMSAppRole3.approle2, value: CMSAppRole3.approle2 },
]
export const listArea = [
    {areaName:'JAKARTA', value:'jakarta'},
    {areaName:'SURABAYA', value: 'surabaya'},
    {areaName:'CIKARANG', value:'cikarang'},
    {areaName:'MEDAN', value:'medan'},
    {areaName:'BANDUNG', value:'bandung'},
    {areaName:'SEMARANG', value:'semarang'},
    {areaName:'MAKASAR', value:'makasar'},
]
export const selectAreas = [
    { key: listArea[0].areaName, value: listArea[0].value },
    { key: listArea[1].areaName, value: listArea[1].value },
    { key: listArea[2].areaName, value: listArea[2].value },
    { key: listArea[3].areaName, value: listArea[3].value },
    { key: listArea[4].areaName, value: listArea[4].value },
    { key: listArea[5].areaName, value: listArea[5].value },
    { key: listArea[6].areaName, value: listArea[6].value },
]
export const itDashboardAllowed = [userRoles.role1]
export const salesDashboardAllowed = [userRoles.role1, userRoles.role3]
export const CMSDashboardAllowed = [userRoles.role1, userRoles.role2]