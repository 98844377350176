import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import {
  ButtonCompNew,
} from '../../../components/button/ButtonComp';
import {
  useProductDispatch,
  useProductState,
} from '../ProductDashboard/ProviderProduct';
// import './technology-style.scss';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import {
  dispatchWithTimeout,
  getAllProduct,
} from '../ProductDashboard/ActionDispatch';
import RenderForm from '../../../components/RenderForm/RenderForm';
import { checkImageDimension } from '../../../utils/checkImage';

const BodyModalCreate = () => {
  const approle = localStorage.getItem('approle');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);
  const dispatch = useProductDispatch();
  const state = useProductState();
  const stateValue = state.valueCreateTech;
  const listColumn = [
    {
      id: 1,
      label: '* Name',
      value: 'technologyName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: '* Description',
      value: 'descTechnology',
      type: 'textarea',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'Link Web',
      value: 'linkLearnMore',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 4,
      label: '* Type Technology',
      value: 'typeTechnology',
      type: approle ? 'readonly-tech' : 'select',
      required: true,
      readOnly: approle ? true : false,
      defaultValue: approle ?? '',
      selectOption: [
        {
          key: 'coding',
          value: 'Coding & Marking',
        },
        {
          key: 'packaging',
          value: 'Packaging',
        },
      ],
      funcCallback: (e) => {
        const copyObj = Object.assign({}, stateValue, {
          typeTechnology: e.target.value,
        });
        dispatch({
          type: 'SET_VALUE_CREATE_TECHNOLOGY',
          payload: copyObj,
        });
      },
    },
    {
      id: 5,
      label: 'Image (max upload 500px x 500px)',
      value: 'imageTechnology',
      type: 'single-image',
      readOnly: false,
    },
  ];

  const setFormValue = (e, index, column) => {
    const copyObj = Object.assign({}, stateValue, { [column]: e.target.value });

    dispatch({
      type: 'SET_VALUE_CREATE_TECHNOLOGY',
      payload: copyObj,
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const res = await checkImageDimension(file, setError, dispatch);
    if (res) {
      if (file.size <= 2 * 1024 * 1024) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
        setError(null);
      } else {
        setError('File size exceeds 2MB.');
        setSelectedFile(null);
        setImagePreview(null);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    try {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        if (selectedFile) {
          formData.append('attachment', selectedFile);
        }
        for (const key in stateValue) {
          const value = stateValue[key];
          if (value) {
            formData.append(key, value);
          } else if(approle){
            formData.append('typeTechnology', approle);
          }
        }
        // for (const pair of formData.entries()) {
        //   const key = pair[0];
        //   const value = pair[1];
        //   console.log(`Key: ${key}, Value: ${value}`);
        // }

        // return;

        const url = `v1/technology/technlogy-master`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios
          .post(`${baseUrl}/${url}`, formData, {
            headers: { Authorization: decSecret },
          })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });

        if (response.status === 200) {
          const options = approle ?? null;
          dispatch(getAllProduct(dispatch, options));
          dispatch({
            type: 'SET_VALUE_CREATE_TECHNOLOGY',
            payload: {},
          });
          dispatchWithTimeout(
            dispatch,
            'SET_MODAL_CREATE_TECHNOLOGY',
            false,
            500
          );
          dispatchWithTimeout(dispatch, 'SET_MODAL_SUCCESS', true, 500);
          return;
        }
        dispatch({ type: 'SET_MODAL_FAILED_MSG', payload: response.data.message });
        dispatch({ type: 'SET_MODAL_FAILED', payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="pe-2"
      >
        {listColumn.map((column, index) => {
          const inputValue = stateValue[column.value] || '';
          return (
            <RenderForm
              keyValue={column.id}
              key={column.id}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              handleFileChange={handleFileChange}
              errorFile={error}
              imagePreview={imagePreview}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Create</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};

export default BodyModalCreate;
