import { createContext, useReducer, useContext } from 'react';

// Step 1: Create a Context
const CompanyStateContext = createContext();
const CompanyDispatchContext = createContext();

// Step 2: Create a reducer function
const CompanyReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA_COMPANY':
      return { ...state, data: action.payload };
    case 'SET_LOADING_COMPANY':
      return { ...state, loading: action.payload };
    case 'SET_MODAL_EDIT_COMPANY':
      return { ...state, showModalEdit: action.payload };
    case 'SET_MODAL_DELETE_COMPANY':
      return { ...state, showModalDelete: action.payload };
    case 'SET_VALUE_CREATE_COMPANY':
      return { ...state, valueCreate: action.payload };
    case 'SET_VALUE_EDIT_COMPANY':
      return { ...state, valueEdit: action.payload };
    case 'SET_VALUE_DELETE_COMPANY':
      return { ...state, valueDelete: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const CompanyProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalEdit: false,
    showModalDelete: false,
    valueCreate: {
      companyName: '',
      companyId: '',
    },
    valueEdit: {},
    valueDelete: {},
  };
  const [state, dispatch] = useReducer(CompanyReducer, initialState);

  return (
    <CompanyStateContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>
        {children}
      </CompanyDispatchContext.Provider>
    </CompanyStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useCompanyState = () => {
  const context = useContext(CompanyStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useCompanyDispatch = () => {
  const context = useContext(CompanyDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { CompanyProvider, useCompanyState, useCompanyDispatch };
