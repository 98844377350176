import { createContext, useReducer, useContext } from 'react';

// Step 1: Create a Context
const AllUsersStateContext = createContext();
const AllUsersDispatchContext = createContext();

// Step 2: Create a reducer function
const AllUsersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA_ALLUSERS':
      return { ...state, data: action.payload };
    case 'SET_LOADING_ALLUSERS':
      return { ...state, loading: action.payload };
    case 'SET_MODAL_EDIT_ALLUSERS':
      return { ...state, showModalEdit: action.payload };
    case 'SET_MODAL_DELETE_ALLUSERS':
      return { ...state, showModalDelete: action.payload };
    case 'SET_VALUE_CREATE_ALLUSERS':
      return { ...state, valueCreate: action.payload };
    case 'SET_VALUE_EDIT_ALLUSERS':
      return { ...state, valueEdit: action.payload };
    case 'SET_VALUE_DELETE_ALLUSERS':
      return { ...state, valueDelete: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const AllUsersProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalEdit: false,
    showModalDelete: false,
    valueCreate: {
      userName: '',
      password: '', 
      fullname: '', 
      email:'', 
      phoneNumber:''
    },
    valueEdit: {},
    valueDelete: {},
  };
  const [state, dispatch] = useReducer(AllUsersReducer, initialState);

  return (
    <AllUsersStateContext.Provider value={state}>
      <AllUsersDispatchContext.Provider value={dispatch}>
        {children}
      </AllUsersDispatchContext.Provider>
    </AllUsersStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useAllUsersState = () => {
  const context = useContext(AllUsersStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useAllUsersDispatch = () => {
  const context = useContext(AllUsersDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { AllUsersProvider, useAllUsersState, useAllUsersDispatch };
