import axios from 'axios';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import { baseUrl } from '../../../service/baseUrl';
// global state
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// component state
import { useCompanyDispatch, useCompanyState } from './ProviderCompany';
import { getAllCompany, dispatchWithTimeout } from './ActionDispatch';
import RenderForm from '../../../components/RenderForm/RenderForm';

const BodyModalEdit = () => {
  const [validated, setValidated] = useState(false);
  const [error] = useState(null);
  // global state
  const globalState = useCSState();
  const globaldispatch = useCSDispatch();
  const key = globalState.key;
  // component state
  const dispatch = useCompanyDispatch();
  const state = useCompanyState();
  const editValue = state.valueEdit;

  const listColumn = [
    {
      id: 1,
      label: 'Name',
      value: 'companyName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'Company ID',
      value: 'companyId',
      type: 'text',
      required: true,
      readOnly: true,
    },
  ];

  const setFormValue = (e, index, column) => {
    // Create a copy of the columns
    const copyObj = Object.assign({}, editValue, { [column]: e.target.value });

    dispatch({
      type: `SET_VALUE_EDIT_${key}`,
      payload: copyObj,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        for (const key in editValue) {
          const value = editValue[key];
          if (value !== undefined) {
            formData[key] = value;
          }
        }

        const url = `companies/`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios({
          method: 'patch',
          url: `${baseUrl}/${url}`,
          data: {
            companyName: formData.companyName,
            companyId: formData.companyId,
          },
          headers: { Authorization: decSecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 201) {
          dispatch(getAllCompany(dispatch));
          dispatchWithTimeout(dispatch, `SET_MODAL_EDIT_${key}`, false, 500);
          dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        globaldispatch({ type: `SET_MODAL_FAILED_${key}`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <section>
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="pe-2"
      >
        {listColumn.map((column, index) => {
          const inputValue = editValue[column.value] || '';
          return (
            <RenderForm
              key={column.value}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              error={error}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Save</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};
export default BodyModalEdit;
