import React from "react";

const TableHeadComp = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers.map((item) => {
          if(item.show === false){
            return null;
          }else{
            return <th key={item.value}>{item.label}</th>;
          }
        })}
      </tr>
    </thead>
  );
};

export default TableHeadComp;
