import { createContext, useReducer, useContext } from "react";

// Step 1: Create a Context
const ProductStateContext = createContext();
const ProductDispatchContext = createContext();

// Step 2: Create a reducer function
const productReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA_PRODUCT":
      return { ...state, dataProduct: action.payload };
    case "SET_DATA_SUB_PRODUCT":
      return { ...state, dataSubProduct: action.payload };
      case "SET_DATA_SUB_PRODUCT_HIDDEN":
        return { ...state, dataSubProductHidden: action.payload };
    case "SET_LOADING_PRODUCT":
      return { ...state, loadingProduct: action.payload };
    case "SET_LOADING_SUB_PRODUCT":
      return { ...state, loadingSubProduct: action.payload };
    case "SET_MODAL_CREATE_TECHNOLOGY":
      return { ...state, showModalCreate: action.payload };
    case "SET_MODAL_CREATE_PRODUCT":
      return { ...state, showModalCreateSubProduct: action.payload };
    case "SET_MODAL_EDIT_TECHNOLOGY":
      return { ...state, showModalEdit: action.payload };
    case "SET_MODAL_EDIT_PRODUCT":
      return { ...state, showModalEditSubProduct: action.payload };
    case "SET_MODAL_DELETE_TECHNOLOGY":
      return { ...state, showModalDeleteTechnology: action.payload };
    case "SET_MODAL_DELETE_PRODUCT":
      return { ...state, showModalDeleteProduct: action.payload };
    case "SET_VALUE_CREATE_TECHNOLOGY":
      return { ...state, valueCreateTech: action.payload };
    case "SET_VALUE_CREATE_PRODUCT":
      return { ...state, valueCreateProduct: action.payload };
    case "SET_VALUE_EDIT_TECHNOLOGY":
      return { ...state, valueEditTech: action.payload };
    case "SET_VALUE_EDIT_PRODUCT":
      return { ...state, valueEditProduct: action.payload };
    case "SET_MODAL_SUCCESS":
      return { ...state, showModalSuccess: action.payload };
    case "SET_MODAL_FAILED":
      return { ...state, showModalFailed: action.payload };
      case "SET_MODAL_FAILED_MSG":
        return { ...state, setModalFailedMsg: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const ProductProvider = ({ children }) => {
  const initialState = {
    dataProduct: [],
    dataSubProduct: [],
    dataSubProductHidden:[],
    loadingProduct: true,
    loadingSubProduct: true,
    showModalCreate: false,
    showModalCreateSubProduct: false,
    showModalEdit: false,
    showModalEditSubProduct: false,
    valueEditTech: {},
    valueEditProduct: {},
    valueCreateTech: {
      technologyName: "",
      typeTechnology: "",
    },
    valueCreateProduct: {
      nameProduct: "",
      technologyId: "",
    },
    showModalSuccess: false,
    showModalFailed: false,
    showModalDeleteTechnology: false,
    showModalDeleteProduct: false,
    setModalFailedMsg: 'something when wrong, try again later'
  };
  const [state, dispatch] = useReducer(productReducer, initialState);

  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useProductState = () => {
  const context = useContext(ProductStateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useProductDispatch = () => {
  const context = useContext(ProductDispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalDispatch must be used within a GlobalProvider");
  }
  return context;
};

export { ProductProvider, useProductState, useProductDispatch };
