import { createContext, useReducer, useContext } from 'react';

// create context/ ganti nama sesuai context yang sedang dibuat
const PhoneNumberListStateContext = createContext();
const PhoneNumberListDispatchContext = createContext();
// state names / ganti dengan nama penanda dari states yang dibutuhkan
const stateGroup = 'PHONENUMBERLIST'
// Create a reducer function
const PhoneNumberListReducer = (state, action) => {
  switch (action.type) {
    case `SET_DATA_${stateGroup}`:
      return { ...state, data: action.payload };
    case `SET_LOADING_${stateGroup}`:
      return { ...state, loading: action.payload };
    case `SET_MODAL_EDIT_${stateGroup}`:
      return { ...state, showModalEdit: action.payload };
    case `SET_MODAL_CREATE_${stateGroup}`:
      return { ...state, showModalCreate: action.payload };
    case `SET_VALUE_EDIT_${stateGroup}`:
      return { ...state, valueEdit: action.payload };
      case `SET_VALUE_CREATE_${stateGroup}`:
        return { ...state, valueCreate: action.payload };
    default:
      return state;
  }
};

// Create a provider component
const PhoneNumberListProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalEdit: false,
    showModalCreate: false,
    valueEdit: {},
    valueCreate:{label:'', phoneNumber:''},
  };
  const [state, dispatch] = useReducer(PhoneNumberListReducer, initialState);

  return (
    <PhoneNumberListStateContext.Provider value={state}>
      <PhoneNumberListDispatchContext.Provider value={dispatch}>
        {children}
      </PhoneNumberListDispatchContext.Provider>
    </PhoneNumberListStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state / ganti nama sesuai kebutuhan
const usePhoneNumberListState = () => {
  const context = useContext(PhoneNumberListStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch / ganti nama sesuai kebutuhan
const usePhoneNumberListDispatch = () => {
  const context = useContext(PhoneNumberListDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};
// ganti nama export susai function di atas
export { PhoneNumberListProvider, usePhoneNumberListState, usePhoneNumberListDispatch };
