import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
// global states
import { useITState, useITDispatch } from '../ITDashboard/ProviderIT';
// ticket states
import { useAllUsersState, useAllUsersDispatch } from './ProviderAllUsers';
import { dispatchWithTimeout, getAllAllUsers } from './ActionDispatch';
import RenderForm from '../../../components/RenderForm/RenderForm';
import { userRoles } from '../../../utils/listRole';
import { useNavigate } from 'react-router-dom';
import ModalComp from '../../../components/modal/ModalComp';

const CreateFirstUser = () => {
  const [imagePreview] = useState(null);
  const [error] = useState(null);
  const [validated, setValidated] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);

  // global state
  const globalState = useITState();
  const globaldispatch = useITDispatch();
  const key = globalState.key;
  // component state
  const dispatch = useAllUsersDispatch();
  const state = useAllUsersState();
  const createValue = state.valueCreate;
  const data = state.data;
  const navigate = useNavigate();
  const [userExist, setUserExist] = useState(false);
  const listColumn = [
    {
      id: 1,
      label: '* Name',
      value: 'fullname',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'username',
      value: 'userName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'password',
      value: 'password',
      type: 'password',
      required: true,
      readOnly: false,
    },
    {
      id: 4,
      label: 'email',
      value: 'email',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
        id: 5,
        label: '* Type Technology',
        value: 'typeTechnology',
        type: 'readonly-tech',
        required: true,
        readOnly: true,
        defaultValue: 'adminit',
        selectOption: [
          {
            key: 'coding',
            value: 'Coding & Marking',
          },
          {
            key: 'packaging',
            value: 'Packaging',
          },
        ],
        funcCallback: (e) => {
        },
      },
  ];

  const setFormValue = (e, index, column) => {
    const copyObj = Object.assign({}, createValue, {
      [column]: e.target.value,
    });
    dispatch({
      type: `SET_VALUE_CREATE_ALLUSERS`,
      payload: copyObj,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    try {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        for (const kunci in createValue) {
          const value = createValue[kunci];
          if (value !== undefined) {
            formData[kunci] = value;
          }
        }
        formData['role'] = userRoles.role1;
        const url = `userapp`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decsecret = window.btoa(rahasia);
        const response = await axios({
          method: 'post',
          url: `${baseUrl}/${url}`,
          data: {
            userName:formData.userName,
            password: formData.password,
            fullname: formData.fullname,
            email:formData.email,
            phoneNumber: formData.phoneNumber,
            role: formData.role,

          },
          headers: { Authorization: decsecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });

        if (response.status === 200) {
          dispatch({
            type: `SET_VALUE_CREATE_ALLUSERS`,
            payload: {},
          });
          setModalSuccess(true)
          return;
        }
        const errorMsg =
          response.data.message === 'user created'
            ? 'User ID already exists'
            : '';
        dispatch({
          type: `SET_VALUE_CREATE_ALLUSERS`,
          payload: {},
        });
        setModalFailed(true);
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };
  const fetchUser = async () => {
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const url = 'userapp';
    const response = await axios
      .get(`${baseUrl}/${url}`, {
        headers: { Authorization: decSecret },
      })
      .catch((e) => {
        console.log(e);
      });
    if(response.data.data.length >= 1){
        navigate('/')
    }
    return response;
  };
  useEffect(()=>{
    fetchUser();
  },[])

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="form-container px-2 px-4"
      >
        {listColumn.map((column, index) => {
          const inputValue = createValue[column.value] || '';
          return (
            <RenderForm
              keyValue={column.id}
              key={column.id}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              errorFile={error}
              imagePreview={imagePreview}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Create</ButtonCompNew>
        </div>
      </Form>
      <ModalComp
        show={modalFailed}
        onHide={() =>setModalFailed(false)}
        ModalHeader={'sorry'}
        buttonText={{ primary: 'close' }}
      >' something when wrong, try again later'
      </ModalComp>
      <ModalComp
        onHide={() => {
            setModalSuccess(false)
            navigate('/')  
        }}
        show={modalSuccess}
        ModalHeader={'awesome!'}
        buttonText={{ primary: 'close' }}
      >
        'account created successfully please sign in'
      </ModalComp>
    </section>
  );
};

export default CreateFirstUser;
