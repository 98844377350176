import Modal from "react-bootstrap/Modal";
import ButtonComp from "../button/ButtonComp";

function ModalComp({
  children,
  onHide,
  show,
  primaryFunc,
  secondFunc,
  thirdFunc,
  buttonText,
  ModalHeader,
  isPrimary,
  isSecondary,
  isThird,
  withBtn,
}) {
  return (

    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <div class="modal-dialog-scrollable">
        <div class="modal-content">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {ModalHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{children}</Modal.Body>
          
          {withBtn && (
            <Modal.Footer>
              {isThird && (
                <ButtonComp clickFunc={thirdFunc} buttonType={"button"} third>
                  {buttonText.third}
                </ButtonComp>
              )}
              {isSecondary && (
                <ButtonComp clickFunc={secondFunc} buttonType={"button"} secondary>
                  {buttonText.secondary}
                </ButtonComp>
              )}
              {isPrimary && (
                <ButtonComp clickFunc={primaryFunc} buttonType={"button"} primary>
                  {buttonText.primary}
                </ButtonComp>
              )}
            </Modal.Footer>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ModalComp;
