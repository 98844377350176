import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';

const getAllMachines = async (dispatch) => {
  dispatch({ type: 'SET_LOADING_MACHINE', payload: true });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'machines';
  const response = await axios
    .get(`${baseUrl}/${url}`, {
      headers: { Authorization: decSecret },
    })
    .catch((e) => {
      console.log(e);
    });

  dispatch({ type: 'SET_DATA_MACHINE', payload: response.data.data });
  setTimeout(() => {
    dispatch({ type: 'SET_LOADING_MACHINE', payload: false });
  }, [1500]);
};

// const getAllSubProduct = async (dispatch) => {
//   dispatch({ type: "SET_LOADING_SUB_PRODUCT", payload: true });
//   const rahasia = process.env.REACT_APP_RAHASIA;
//   const decSecret = window.btoa(rahasia);
//   const url = "api/v1/technology/technlogy-subproduct-master";
//   const response = await axios
//     .get(`${baseUrl}/${url}`, {
//       headers: { Authorization: decSecret },
//     })
//     .catch((e) => {
//       console.log(e);
//     });
//   dispatch({ type: "SET_DATA_SUB_PRODUCT", payload: response.data.data });
//   setTimeout(() => {
//     dispatch({ type: "SET_LOADING_SUB_PRODUCT", payload: false });
//   }, [1500]);
// };

function dispatchWithTimeout(dispatch, actionType, payload, delay) {
  setTimeout(() => {
    dispatch({ type: actionType, payload });
  }, delay);
}

export { getAllMachines, dispatchWithTimeout };
