import { useEffect, useState } from 'react';
// global states
import { useITState, useITDispatch } from '../ITDashboard/ProviderIT';
// ticket states
import { useAllUsersState, useAllUsersDispatch } from './ProviderAllUsers';
import { getAllAllUsers } from './ActionDispatch';

import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import ModalComp from '../../../components/modal/ModalComp';
import BodyModalCreate from './BodyModalCreate';
import BodyModalEdit from './BodyModalEdit';
import BodyModalDelete from './BodyModalDelete';
import UserDetailTable from '../../../components/UserDetailTable/UserDetailTable';
import { BiEdit, BiTrash } from "react-icons/bi";

const AllUsersMaster = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showModalUserDetail, setShowModalUserDetail] = useState(false);
  const [initialDataUser, setInitialDataUser] = useState({});
  // global state
  const globalState = useITState();
  const globalDispatch = useITDispatch();
  const key = globalState.key;
  // ticket states
  const state = useAllUsersState();
  const dispatch = useAllUsersDispatch();
  const loading = state.loading;
  const data = state.data;

  const fetchData = async () => {
    dispatch(getAllAllUsers(dispatch));
  };

  const tableColumn = [
    {
      id: 1,
      value: 'fullname',
      label: 'Full Name',
      type: 'string',
      search: true,
    },
    {
      id: 2,
      value: 'email',
      label: 'email',
      type: 'string',
      search: true,
    },
    {
      id: 3,
      value: 'userName',
      label: 'User Name',
      type: 'string',
      search: true,
    },
    {
      id: 4,
      value: 'action',
      label: 'Action',
      type: 'button',
      search: false,
      renderButton: [
        {
          id: 1,
          type: 'edit',
          name: [<BiEdit />],
          handler: (e) => {
            dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_EDIT_${key}`, payload: e });
          },
        },
        {
          id: 2,
          type: 'hapus',
          name: [<BiTrash />],
          handler: (e) => {
            dispatch({ type: `SET_MODAL_DELETE_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_DELETE_${key}`, payload: e });
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <div>
      <div className="search_wrap mt-4 py-2">
        <input
          type="text"
          placeholder="Search Here"
          required
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>

      <MainTableCustom
        header={tableColumn}
        data={data}
        loading={loading}
        searchTerm={searchTerm}
      />

      <ModalComp
        show={globalState.showModalCreateAllUsers}
        onHide={() =>
          globalDispatch({ type: `SET_MODAL_CREATE_ALLUSERS`, payload: false })
        }
        ModalHeader={'AllUsers data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalCreate />
      </ModalComp>

      <ModalComp
        show={state.showModalEdit}
        onHide={() =>
          dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: false })
        }
        ModalHeader={'AllUsers Data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalEdit />
      </ModalComp>

      <ModalComp
        show={state.showModalDelete}
        ModalHeader={`Confirm Delete: ${state.valueDelete.userName}`}
        onHide={() =>
          dispatch({ type: `SET_MODAL_DELETE_${key}`, payload: false })
        }
      >
        <BodyModalDelete />
      </ModalComp>
      <ModalComp
        show={showModalUserDetail}
        onHide={() => {
          setShowModalUserDetail(false);
        }}
        ModalHeader={'User data:'}
        buttonText={{ primary: 'close' }}
        primaryFunc={() => setShowModalUserDetail(false)}
      >
        <UserDetailTable
          initialDataUser={initialDataUser}
          setShowModalUserDetail={setShowModalUserDetail}
          userOf={'this'}
        />
      </ModalComp>
    </div>
  );
};

export default AllUsersMaster;
