import { createContext, useReducer, useContext } from 'react';

// Step 1: Create a Context
const CSStateContext = createContext();
const CSDispatchContext = createContext();

// Step 2: Create a reducer function
const CSReducer = (state, action) => {
  switch (action.type) {
    case 'SET_KEY_TAB_COMPANY':
      return { ...state, key: action.payload };
    case 'SET_MODAL_CREATE_COMPANY':
      return { ...state, showModalCreateCompany: action.payload };
    case 'SET_MODAL_CREATE_USERCOMPANY':
      return { ...state, showModalCreateUserCompany: action.payload };
    case 'SET_MODAL_CREATE_MACHINE':
      return { ...state, showModalCreateMachine: action.payload };
    case 'SET_MODAL_CREATE_TICKET':
      return { ...state, showModalCreateTicket: action.payload };
    case 'SET_MODAL_SUCCESS':
      return { ...state, showModalSuccess: action.payload };
    case 'SET_MODAL_FAILED':
      return { ...state, showModalFailed: action.payload };
    case 'SET_FAILED_MESSAGE':
      return { ...state, failedMessage: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const CSProvider = ({ children }) => {
  const initialState = {
    key: '',
    showModalCreateCompany: false,
    showModalCreateUserCompany: false,
    showModalCreateMachine: false,
    showModalCreateTicket: false,
    showModalSuccess: false,
    showModalFailed: false,
    failedMessage: '',
  };
  const [state, dispatch] = useReducer(CSReducer, initialState);

  return (
    <CSStateContext.Provider value={state}>
      <CSDispatchContext.Provider value={dispatch}>
        {children}
      </CSDispatchContext.Provider>
    </CSStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useCSState = () => {
  const context = useContext(CSStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useCSDispatch = () => {
  const context = useContext(CSDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { CSProvider, useCSState, useCSDispatch };
