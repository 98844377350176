const filterByValue = (data, value) => {
  return (
    JSON.stringify(data)?.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
};

function filterObjectByKeys(obj, keys) {
  const filteredObj = {};

  for (const key in obj) {
    if (keys.includes(key)) {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
}

export { filterByValue, filterObjectByKeys };
