import React, { useState } from "react";
import "./button-component.scss";

function ButtonComp({
  children,
  clickFunc,
  type,
  primary,
  secondary,
  third,
  hapus,
  edit,
  up,
  down,
  disable,
}) {
  return (
    <>
      {primary && (
        <button onClick={clickFunc} type={type} className="primary-btn">
          {children}
        </button>
      )}
      {secondary && (
        <button onClick={clickFunc} type={type} className="secondary-btn">
          {children}
        </button>
      )}
      {third && (
        <button onClick={clickFunc} type={type} className="third-btn">
          {children}
        </button>
      )}
      {edit && (
        <button onClick={clickFunc} type={type} className="edit">
          {children}
        </button>
      )}
      {hapus && (
        <button onClick={clickFunc} type={type} className="hapus">
          {children}
        </button>
      )}
      {up && (
        <button onClick={clickFunc} type={type} className="up">
          {children}
        </button>
      )}
      {down && (
        <button onClick={clickFunc} type={type} className="down">
          {children}
        </button>
      )}
      {disable && (
        <button onClick={clickFunc} type={type} className="disable-btn">
          {children}
        </button>
      )}
    </>
  );
}

export default ButtonComp;

function ButtonCompNew({
  onClick,
  children,
  type,
  variant,
  debounceTime = 2000,
  disabled = false,
}) {
  const [isClickPending, setIsClickPending] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(disabled);
  // console.log(type, "FROM BOTTON");

  const handleButtonClick = () => {
    if (type === "submit") {
      return;
    }

    if (!isClickPending) {
      setDisabledBtn(true);
      setIsClickPending(true);
      onClick();
      setTimeout(() => {
        setIsClickPending(false);
        setDisabledBtn(false);
      }, debounceTime);
    }
  };

  let className = "primary-btn";
  if (variant === "primary") {
    className = "primary-btn";
  }
  if (variant === "secondary") {
    className = "secondary-btn";
  }
  if (variant === "third") {
    className = "third-btn";
  }
  if (variant === "hapus") {
    className = "hapus";
  }
  if (variant === "edit") {
    className = "edit";
  }
  if (variant === "up") {
    className = "up";
  }
  if (variant === "down") {
    className = "down";
  }
  return (
    <button
      disabled={disabledBtn}
      onClick={handleButtonClick}
      type={type}
      className={className}>
      {children}
    </button>
  );
}

export { ButtonCompNew };
