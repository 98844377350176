import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';

const getAllTickets = async (dispatch) => {
  dispatch({ type: 'SET_LOADING_TICKET', payload: true });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'tickets';
  const response = await axios
    .get(`${baseUrl}/${url}`, {
      headers: { Authorization: decSecret },
    })
    .catch((e) => {
      console.log(e);
    });

  dispatch({ type: 'SET_DATA_TICKET', payload: response.data.data });
  setTimeout(() => {
    dispatch({ type: 'SET_LOADING_TICKET', payload: false });
  }, [1500]);
};

function dispatchWithTimeout(dispatch, actionType, payload, delay) {
  setTimeout(() => {
    dispatch({ type: actionType, payload });
  }, delay);
}

export { getAllTickets, dispatchWithTimeout };
