import { useEffect, useState } from 'react';
// global states
import { useITState} from '../ITDashboard/ProviderIT';
// sales user states
import { useSalesUsersState, useSalesUsersDispatch } from './ProviderSalesUsers';
import { getSalesUsers } from './SalesDispatch';
import { BiEdit, BiTrash } from "react-icons/bi";
import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import ModalComp from '../../../components/modal/ModalComp';
import BodyModalEdit from './BodyModalEdit';

const UserRegisteredSales = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // global state
  const globalState = useITState();
  const key = globalState.key;
  // ticket states
  const state = useSalesUsersState();
  const dispatch = useSalesUsersDispatch();
  const loading = state.loading;
  const data = state.data;

  const fetchData = async () => {
    dispatch(getSalesUsers(dispatch));
  };

  const tableColumn = [
    {
      id: 1,
      value: 'salesId',
      label: 'ID',
      type: 'string',
      search: true,
    },
    {
      id: 2,
      value: 'fullname',
      label: 'Full Name',
      type: 'string',
      search: true,
    },
    {
      id: 3,
      value: 'email',
      label: 'Email',
      type: 'string',
      search: true,
    },
    {
      id: 4,
      value: 'userName',
      label: 'User Name',
      type: 'string',
      search: true,
    },
    {
      id: 5,
      value: 'approle',
      label: 'APP Role',
      type: 'string',
      search: true,
    },
    {
      id: 6,
      value: 'action',
      label: 'Action',
      type: 'button',
      search: false,
      renderButton: [
        {
          type: 'edit',
          name: [<BiEdit />],
          handler: (e) => {
            dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_EDIT_${key}`, payload: e });
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <div>
      <div className="search_wrap mt-4 py-2">
        <input
          type="text"
          placeholder="Search Here"
          required
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>

      <MainTableCustom
        header={tableColumn}
        data={data}
        loading={loading}
        searchTerm={searchTerm}
      />

      <ModalComp
        show={state.showModalEdit}
        onHide={() =>
          dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: false })
        }
        ModalHeader={'AllUsers Data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalEdit />
      </ModalComp>
    </div>
  );
};

export default UserRegisteredSales;
