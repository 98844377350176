import { React, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonComp from '../../components/button/ButtonComp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../service/baseUrl';
import { userRolesData } from '../../utils/listRole';
import Header from '../../components/Header/Header';

const validationSchema = yup.object().shape({
  username: yup.string().min(3).required('Username must be at least 3 characters'),
  password: yup.string().min(8).required(),
});

const LoginPage = ({ setIsAuth, setUserLogin }) => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('logged');
  // const isUser = localStorage.getItem('salesid');
  const {
    setError,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [intip, setIntip] = useState({
    password: '',
    showPassword: false,
  });

  const handleLogin = async (values) => {
    const url = `userapp/login`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decsecret = window.btoa(rahasia);
    await axios({
      method: 'post',
      url: `${baseUrl}/${url}`,
      data: {
        userName: values.username,
        password: values.password,
      },
      headers: { Authorization: decsecret },
    })
      .then((response) => {
        const role = response.data.data.role;
        const approle = response.data.data.approle;
        const salesData = response.data.data;
        localStorage.setItem('logged', true);
        localStorage.setItem('role', role);
        localStorage.setItem('approle', approle);
        localStorage.setItem('salesname', salesData.fullname);
        localStorage.setItem('salesid', salesData.salesId);
        localStorage.setItem('userID', salesData.userID);
        setUserLogin(salesData);
        setIsAuth(true);
        const dataUser = JSON.stringify([response.data.data]);
        localStorage.setItem('userData', dataUser);
        setTimeout(() => {
          const navigateTo = userRolesData[role].homepage;
          navigate(navigateTo);
        }, 1500);
      })
      .catch((e) => {
        alert('User id or password not valid');
      });
  };

  const handleClickShowPassword = () => {
    setIntip({
      ...intip,
      showPassword: !intip.showPassword,
    });
  };
  const handlePasswordChange = (prop) => (event) => {
    setIntip({
      ...intip,
      [prop]: event.target.value,
    });
    // setFormValue(event, index, column.value)
  };
  useEffect(() => {
    if (isLogin) {
      const salesData = localStorage.getItem('userData');
      console.log(salesData);
      // setUserLogin(salesData);
      const role = localStorage.getItem('role');
      const navigateTo = userRolesData[role]?.homepage;
      if (navigateTo) {
        navigate(navigateTo);
      } else {
        alert('Sorry not allowed');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="LoginPage">
      <div className="container">
        <article
          className="card-body mx-auto pt-2 pb-5 card bg-light"
          style={{ maxWidth: '400px' }}
        ><Header />
          <strong><p className="card-title pt-3 text-center">Please log in to continue</p></strong>
          <Form onSubmit={handleSubmit(handleLogin)}>
            <Form.Group className="form-group">
              <Form.Label>Username</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                placeholder="Username"
                name="username"
                id="username"
                {...register('username')}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={intip.showPassword ? 'text' : 'password'}
                placeholder="Password"
                name="password"
                id="password"
                {...register('password')}
                isInvalid={!!errors.password}
                value={intip.password}
                onChange={handlePasswordChange('password')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Show password"
              className="pb-1"
              onClick={handleClickShowPassword}
            />
            <div className="text-end">
              <ButtonComp type={'submit'} primary>
                Log in
              </ButtonComp>
            </div>
          </Form>
        </article>
      </div>
    </div>
  );
};

export default LoginPage;
