import React, { useState } from 'react';
import ButtonComp, { ButtonCompNew } from '../button/ButtonComp';
import './table-style.scss';
import { baseUrl } from '../../service/baseUrl';
import { Form } from 'react-bootstrap';

const CustomTableCell = ({ row, header, prevRow, nextRow }) => {


  const formatDate = (date) => {
    const tanggal = date.split('T')[0];
    const tgl = tanggal.split('-');
    const tglJadi = `${tgl[2]}/${tgl[1]}`;
    return `${tglJadi}`;
  };
  const formatTime = (date) => {
    const jam = date.split('T')[1].split('.')[0];
    const jam2 = jam.split(':');
    const jamJadi = `${parseInt(jam2[0]) + 7}:${jam2[1]}`;
    return `${jamJadi}`;
  };
  switch (header.type) {
    case 'sort':
      return (
        <td key={header.id} className="product-wrapper">
          <div className="button-container">
          {header.renderButton?.map((item, index) => {
            if(item.type === 'up' && prevRow === undefined){
              return null;
            };
            if(item.type === 'down' && nextRow === undefined){
              return null;
            };
              return (
                <ButtonCompNew
                key={index}
                  type={'button'}
                  variant={item.type ? item.type : null}
                  onClick={() => {
                    // console.log('alldata', allData)
                    // console.log('current row', row);
                    // console.log('index', currentIndex);
                    item.handler(row, prevRow, nextRow)}}
                >
                  {item.name}
                </ButtonCompNew>
              );
            })}
          </div>
        </td>
      );
    case 'button':
      return (
        <td key={header.id} className="product-wrapper">
          <div className="button-container">
            {header.renderButton?.map((item, index) => {
              return (
                <ButtonCompNew
                key={header.id}
                  type={'button'}
                  variant={item.type ? item.type : null}
                  onClick={() => item.handler(row)}
                >
                  {item.name}
                </ButtonCompNew>
              );
            })}
          </div>
        </td>
      );
    case 'phone-user':
      return (
        <td
        key={header.id}
          className="phone_user_click"
          onClick={() => header.func(row)}
        >
          +{row[header.value]}
        </td>
      );
    case 'date':
      return (
        <td key={header} onClick={() => header.func(row)}>
          {formatDate(row['createdAt'])}
        </td>
      );
    case 'time':
      return (
        <td key={header} onClick={() => header.func(row)}>
          {formatTime(row['createdAt'])}
        </td>
      );
    case 'image':
      return (
        <td className="product-wrapper" key={header}>
          <img
            crossOrigin="anonymous"
            className="product-image"
            src={process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_API_URL}${row[header.value]}` : `${row[header.value]}`}
            alt="product-img"
          />
        </td>
      );
      case 'display':
      return (
        <td className="product-wrapper" key={header}>
          {/* <Form> */}
            <Form.Check // prettier-ignore
              type="switch"
              id="display"
              value={header.valueDisplay}
              onChange={(e) => {
                // setDisplay(!display);
                // console.log(e.target.value,'biji kejepit');
                header.handler(row.id)
            }}
              
              />
          {/* </Form> */}
        </td>
      );
    case 'custom-user':
      return (
        <td className="action-table-data">
          {header.renderButton.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {row.currentSales === item.salesId && (
                  <ButtonComp
                    buttonType={'button'}
                    primary
                    clickFunc={() => item.handler(row)}
                  >
                    edit
                  </ButtonComp>
                )}
                {row.currentSales !== item.salesId && (
                  <ButtonComp buttonType={'button'} disable>
                    edit
                  </ButtonComp>
                )}
              </React.Fragment>
            );
          })}
        </td>
      );
    default:
      return <td key={header}>{row[header.value]}</td>;
  }
};

export default CustomTableCell;
