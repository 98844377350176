import axios from 'axios';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import { baseUrl } from '../../../service/baseUrl';
// global state
import { useITState, useITDispatch } from '../ITDashboard/ProviderIT';
// component state
import { useAllUsersDispatch, useAllUsersState } from './ProviderAllUsers';
import { getAllAllUsers, dispatchWithTimeout } from './ActionDispatch';
import RenderForm from '../../../components/RenderForm/RenderForm';
import { selectAreas, selectRoles} from '../../../utils/listRole';

const BodyModalEdit = () => {
  const [validated, setValidated] = useState(false);
  const [error] = useState(null);
  // global state
  const globalState = useITState();
  const globaldispatch = useITDispatch();
  const key = globalState.key;
  // component state
  const dispatch = useAllUsersDispatch();
  const state = useAllUsersState();
  const editValue = state.valueEdit;

  const listColumn = [
    {
      id: 1,
      label: '* Name',
      value: 'fullname',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'username',
      value: 'userName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'password',
      value: 'password',
      type: 'password',
      required: false,
      readOnly: false,
    },
    {
      id: 4,
      label: 'email',
      value: 'email',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 5,
      label: 'role',
      value: 'role',
      type: 'select',
      defaultSelect: editValue.role,
      selectOption: selectRoles,
      funcCallback: (e) => {
        const copyObj = Object.assign({}, editValue, {
          role: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_EDIT_${key}`,
          payload: copyObj,
        });
      },
      required: false,
      readOnly: false,
    },
    {
      id: 6,
      label: 'area',
      value: 'area',
      type: 'select',
      defaultSelect: editValue.area,
      selectOption: selectAreas,
      funcCallback: (e) => {
        const copyObj = Object.assign({}, editValue, {
          area: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_EDIT_ALLUSERS`,
          payload: copyObj,
        });
      },
      required: false,
      readOnly: false,
    },
    {
      id: 7,
      label: 'phone number',
      value: 'phoneNumber',
      type: 'text',
      required: false,
      readOnly: false,
    },
  ];

  const setFormValue = (e, index, column) => {
    // Create a copy of the columns
    const copyObj = Object.assign({}, editValue, { [column]: e.target.value });

    dispatch({
      type: `SET_VALUE_EDIT_${key}`,
      payload: copyObj,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        for (const key in editValue) {
          const value = editValue[key];
          if (value !== undefined) {
            formData[key] = value;
          }
        }
        console.log('form data dari body edit all users', formData)
        const url = `userapp/`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decsecret = window.btoa(rahasia);
        const response = await axios({
          method: 'patch',
          url: `${baseUrl}/${url}`,
          data: {...formData},
          headers: { Authorization: decsecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 201) {
          dispatch(getAllAllUsers(dispatch));
          dispatchWithTimeout(dispatch, `SET_MODAL_EDIT_${key}`, false, 500);
          dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        globaldispatch({ type: `SET_MODAL_FAILED_${key}`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="form-container px-2 px-4"
      >
        {listColumn.map((column, index) => {
          const inputValue = editValue[column.value] || '';
          return (
            <RenderForm
              key={column.value}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              error={error}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Save</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};
export default BodyModalEdit;
