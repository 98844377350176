import { useEffect, useState } from 'react';
// global states
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// ticket states
import { useTicketState, useTicketDispatch } from './ProviderTicket';
import { getAllTickets } from './ActionDispatch';

import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import ModalComp from '../../../components/modal/ModalComp';
import BodyModalCreate from './BodyModalCreate';
import BodyModalEdit from './BodyModalEdit';
import BodyModalDelete from './BodyModalDelete';

const TicketMaster = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // global state
  const globalState = useCSState();
  const globalDispatch = useCSDispatch();
  const key = globalState.key;

  // ticket states
  const state = useTicketState();
  const dispatch = useTicketDispatch();
  const loading = state.loading;
  const data = state.data;

  const fetchData = async () => {
    dispatch(getAllTickets(dispatch));
  };

  const tableColumn = [
    {
      value: 'ticketId',
      label: 'Ticket ID',
      type: 'string',
      search: true,
    },
    {
      value: 'machine',
      label: 'Machine',
      type: 'string',
      search: true,
    },
    {
      value: 'companyId',
      label: 'Company ID',
      type: 'string',
      search: true,
    },
    {
      value: 'statusTicket',
      label: 'Status Ticket',
      type: 'string',
      search: true,
    },
    {
      value: 'email',
      label: 'Email',
      type: 'string',
      search: true,
    },
    {
      value: 'action',
      label: 'Action',
      type: 'button',
      search: false,
      renderButton: [
        {
          name: 'status',
          handler: (e) => {
            dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_EDIT_${key}`, payload: e });
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="search_wrap mt-4 py-2">
        <input
          type="text"
          placeholder="Search Here"
          required
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>

      <MainTableCustom
        header={tableColumn}
        data={data}
        loading={loading}
        searchTerm={searchTerm}
      />

      <ModalComp
        show={globalState.showModalCreateTicket}
        onHide={() =>
          globalDispatch({ type: `SET_MODAL_CREATE_${key}`, payload: false })
        }
        ModalHeader={`${key.toLowerCase()} data:`}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalCreate />
      </ModalComp>

      <ModalComp
        show={state.showModalEdit}
        onHide={() =>
          dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: false })
        }
        ModalHeader={`${key.toLowerCase()} data:`}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalEdit />
      </ModalComp>
      <ModalComp
        show={state.showModalDelete}
        onHide={() =>
          dispatch({ type: `SET_MODAL_DELETE_${key}`, payload: false })
        }
        ModalHeader={`Confirm Delete ${key.toLowerCase()} data:`}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalDelete value={state.valueDelete} />
      </ModalComp>
    </div>
  );
};

export default TicketMaster;
