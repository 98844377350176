import React, { useEffect, useState } from "react";
import CustomTableCell from "./CustomTableCell";
import { filterByValue, filterObjectByKeys } from "../../utils/filterValue";

const TableBodyComp = (props) => {
  const { data, headers, loading, searchTerm } = props;
  const [search, setSearch] = useState([]);

  const searchAble = async () => {
    try {
      const searchArr = await Promise.all(
        headers.filter((item) => item.search === true).map((item) => item.value)
      );
      setSearch(searchArr);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    searchAble();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tbody>
      {loading ? (
        <tr>
          <td className="no-user-found" colSpan={headers.length}>
            Loading...
          </td>
        </tr>
      ) : data?.length > 0 ? (
        data
          .filter((value) => {
            if (searchTerm === "") {
              return value;
            } else {
              const filteredObjResult = filterObjectByKeys(value, search);
              const result = filterByValue(filteredObjResult, searchTerm);
              return result;
            }
          })
          .map((row, index) => {
            return (
                <tr key={`${index} row.id`}>
                {headers.map((header) => {
                  if(header.show === false){
                    return null;
                  }else{
                    return (
                      <CustomTableCell key={`${index} ${header.id}`} row={row} prevRow={data[index - 1]} nextRow={data[index + 1]} header={header} />
                    );
                  }
                })}
              </tr>
            );
          })
      ) : (
        <tr>
          <td style={{ textAlign: "center" }} colSpan={headers.length}>
            Data not found.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBodyComp;
