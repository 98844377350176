import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';

const getAllUsers = async (dispatch) => {
  dispatch({ type: 'SET_LOADING_ALLUSERS', payload: true });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'userapp/';
  const response = await axios
    .get(`${baseUrl}/${url}`, {
      headers: { Authorization: decSecret },
    })
    .catch((e) => {
      console.log(e);
    });

  dispatch({ type: 'SET_DATA_ALLUSERS', payload: response?.data?.data });
  setTimeout(() => {
    dispatch({ type: 'SET_LOADING_ALLUSERS', payload: false });
  }, [1500]);
};
const getAllUsersWhere = async (dispatch) => {
  dispatch({ type: 'SET_LOADING_ALLUSERS', payload: true });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'userapp/getwhere';
  await axios({
    method: 'post',
    url: `${baseUrl}/${url}`,
    data: {
      where: {'role':''}
    },
    headers: { Authorization: decSecret },
  })
    .then((response) => {
      dispatch({ type: 'SET_DATA_ALLUSERS', payload: response?.data?.data });
      setTimeout(() => {
        dispatch({ type: 'SET_LOADING_ALLUSERS', payload: false });
      }, [1500]);
      return;
    })
    .catch((e) => {
      return e.response;
    });

};

function dispatchWithTimeout(dispatch, actionType, payload, delay) {
  setTimeout(() => {
    dispatch({ type: actionType, payload });
  }, delay);
}

export { getAllUsers, dispatchWithTimeout };
