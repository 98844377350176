import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import RenderForm from "../../../components/RenderForm/RenderForm";
import ButtonComp from "../../../components/button/ButtonComp";
import { baseUrl } from "../../../service/baseUrl";
import {
  dispatchWithTimeout,
  getAllSubProduct,
} from "../ProductDashboard/ActionDispatch";
import {
  useProductDispatch,
  useProductState,
} from "../ProductDashboard/ProviderProduct";
import { checkImageDimension } from "../../../utils/checkImage";
const BodyModalEdit = () => {
  const approle = localStorage.getItem('approle');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);
  const dispatch = useProductDispatch();
  const state = useProductState();
  const stateValue = state.valueEditProduct;
  //   const stateTechnology = state.dataProduct;

  const listColumn = [
    {
      id: 1,
      label: "* Name Product",
      value: "nameProduct",
      type: "text",
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: "* Description",
      value: "desciptionProduct",
      type: "textarea",
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: "Link Web",
      value: "linkWeb",
      type: "text",
      required: true,
      readOnly: false,
    },

    {
      id: 4,
      label: "Link Download",
      value: "linkDownload",
      type: "text",
      required: true,
      readOnly: false,
    },
    {
      id: 5,
      label: "Image (max upload 2mb)",
      value: "imageProduct",
      type: "single-image",
      readOnly: false,
      required: false,
    },
  ];

  const setFormValue = (e, index, column) => {
    // Create a copy of the columns
    const copyObj = Object.assign({}, stateValue, { [column]: e.target.value });

    dispatch({
      type: "SET_VALUE_EDIT_PRODUCT",
      payload: copyObj,
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const res = await checkImageDimension(file, setError, dispatch);
    if (res) {
      if (file.size <= 2 * 1024 * 1024) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
        setError(null);
      } else {
        setError("File size exceeds 2MB.");
        setSelectedFile(null);
        setImagePreview(null);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        if (selectedFile) {
          formData.append("attachment", selectedFile);
        }
        for (const key in stateValue) {
          const value = stateValue[key];
          if (value !== undefined) {
            formData.append(key, value);
          }
        }

        for (const pair of formData.entries()) {
          const key = pair[0];
          const value = pair[1];
          console.log(`Key: ${key}, Value: ${value}`);
        }

        const url = `v1/technology/technlogy-subproduct-master/${stateValue.id}`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios
          .patch(`${baseUrl}/${url}`, formData, {
            headers: { Authorization: decSecret },
          })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 200) {
          const options = approle ?? null;
          dispatch(getAllSubProduct(dispatch, options));
          dispatchWithTimeout(dispatch, "SET_MODAL_EDIT_PRODUCT", false, 1000);
          dispatchWithTimeout(dispatch, "SET_MODAL_SUCCESS", true, 1000);
          return;
        }
        dispatch({ type: "SET_MODAL_FAILED", payload: true });
        return;
      }
    } catch (e) {
      console.log(e, "catch error handle submit");
    }
  };

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="pe-2">
        {listColumn.map((column, index) => {
          const inputValue = stateValue[column.value] || "";
          return (
            <RenderForm
              key={column.value}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              handleFileChange={handleFileChange}
              error={error}
              imagePreview={imagePreview}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonComp buttonType={"submit"} primary>
            Save
          </ButtonComp>
        </div>
      </Form>
    </section>
  );
};

export default BodyModalEdit;
