import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import ButtonComp from '../../../components/button/ButtonComp';

import { useCSDispatch, useCSState } from './ProviderCS';

import { CompanyProvider } from '../CompanyMaster/ProviderCompany';
import CompanyMaster from '../CompanyMaster/CompanyMaster';

import { UserCompanyProvider } from '../UserCompanyMaster/ProviderUserCompany';
import UserCompanyMaster from '../UserCompanyMaster/UserCompanyMaster';

import { MachineProvider } from '../MachineMaster/ProviderMachine';
import MachineMaster from '../MachineMaster/MachineMaster';

import { TicketProvider } from '../TicketMaster/ProviderTicket';
import TicketMaster from '../TicketMaster/TicketMaster';

import ModalComp from '../../../components/modal/ModalComp';

const CSDashboard = () => {
  const isLogin = localStorage.getItem('loggedAdminCS');
  const userData = JSON.parse(localStorage.getItem('userData'));
  const dispatch = useCSDispatch();
  const state = useCSState();
  const navigate = useNavigate();
  const key = state.key;
  const errorMessage = state.failedMessage;

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate('/login-admin-cs');
    }, 500);
  };

  const activeTab = (k) => {
    dispatch({ type: `SET_KEY_TAB_COMPANY`, payload: k });
  };
  const handleShowCreateModal = () => {
    if (key === 'COMPANY') {
      dispatch({ type: `SET_MODAL_CREATE_COMPANY`, payload: true });
    } else if (key === 'MACHINE') {
      dispatch({ type: `SET_MODAL_CREATE_MACHINE`, payload: true });
    } else if (key === 'TICKET') {
      dispatch({ type: `SET_MODAL_CREATE_TICKET`, payload: true });
    }
  };

  useEffect(() => {
    dispatch({ type: `SET_KEY_TAB_COMPANY`, payload: 'COMPANY' });
    if (!isLogin && !userData) {
      navigate('/login-admin-cs');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ProductDashboard">
      <div className="sales-container">
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <p>Login User: {userData ? userData[0].username : null} </p>
            <p>Role : {userData ? userData[0].role : null} </p>
          </div>
            <div className="button-cs">              
              <div className="">
                <ButtonComp
                  buttonType={'button'}
                  third
                  clickFunc={() => handleLogout()}
                >
                  Log out
                </ButtonComp>
              </div>
            </div>
        </div>
      </div>

      <CompanyProvider>
        <MachineProvider>
          <Tabs
            activeKey={key}
            onSelect={(k) => activeTab(k)}
            fill
            justify
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="COMPANY" title="Company">
              <div className="text-end">
                <ButtonComp
                  clickFunc={() => handleShowCreateModal()}
                  buttonType={'button'}
                  primary
                >
                  + Company
                </ButtonComp>
              </div>
              <div className='company-class'>
                <CompanyMaster />
              </div>
            </Tab>
            <Tab eventKey="USERCOMPANY" title="Usercompany">
              <UserCompanyProvider>
              <div className='usercompany-class'>
                <UserCompanyMaster />
              </div>
              </UserCompanyProvider>
            </Tab>
            <Tab eventKey="MACHINE" title="Machine">
              <div className="text-end">
                <ButtonComp
                  clickFunc={() => handleShowCreateModal()}
                  buttonType={'button'}
                  primary
                >
                  + Machine
                </ButtonComp>
              </div>
              <div className='machine-class'>
                <MachineMaster />
              </div>
            </Tab>
            <Tab eventKey="TICKET" title="Ticket">
              <div className="text-end">
                <ButtonComp
                  clickFunc={() => handleShowCreateModal()}
                  buttonType={'button'}
                  primary
                >
                  + Ticket
                </ButtonComp>
              </div>
              <TicketProvider>
              <div className='ticket-class'>
                <TicketMaster />
              </div>
              </TicketProvider>
            </Tab>
          </Tabs>
        </MachineProvider>
      </CompanyProvider>
      <ModalComp
        show={state.showModalFailed}
        onHide={() => dispatch({ type: `SET_MODAL_FAILED`, payload: false })}
        ModalHeader={'sorry'}
        buttonText={{ primary: 'close' }}
      >
        {errorMessage ? errorMessage : ' something when wrong, try again later'}
      </ModalComp>
      <ModalComp
        onHide={() => dispatch({ type: `SET_MODAL_SUCCESS`, payload: false })}
        show={state.showModalSuccess}
        ModalHeader={'updated!'}
        buttonText={{ primary: 'close' }}
      >
        successfully updated!
      </ModalComp>
    </div>
  );
};

export default CSDashboard;
