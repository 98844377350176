import { createContext, useReducer, useContext } from 'react';

// Step 1: Create a Context
const ITStateContext = createContext();
const ITDispatchContext = createContext();

const stateGroup = 'IT'
// Step 2: Create a reducer function
const ITReducer = (state, action) => {
  switch (action.type) {
    case 'SET_KEY_TAB_OPEN':
      return { ...state, key: action.payload };
    case 'SET_MODAL_CREATE_ALLUSERS':
      return { ...state, showModalCreateAllUsers: action.payload };
    case `SET_MODAL_ACCOUNT_${stateGroup}`:
      return { ...state, showModalAccount: action.payload };
    case `SET_VALUE_ACCOUNT_${stateGroup}`:
      return { ...state, valueAccount: action.payload };
    case 'SET_MODAL_SUCCESS':
      return { ...state, showModalSuccess: action.payload };
    case 'SET_MODAL_FAILED':
      return { ...state, showModalFailed: action.payload };
    case 'SET_FAILED_MESSAGE':
      return { ...state, failedMessage: action.payload };
      case 'SET_SUCCESS_MESSAGE':
        return { ...state, successMessage: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const ITProvider = ({ children }) => {
  const initialState = {
    key: '',
    showModalCreateAllUsers: false,
    showModalAccount: false,
    showModalSuccess: false,
    showModalFailed: false,
    failedMessage: '',
    valueAccount: {},
    successMessage:'',
  };
  const [state, dispatch] = useReducer(ITReducer, initialState);

  return (
    <ITStateContext.Provider value={state}>
      <ITDispatchContext.Provider value={dispatch}>
        {children}
      </ITDispatchContext.Provider>
    </ITStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useITState = () => {
  const context = useContext(ITStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useITDispatch = () => {
  const context = useContext(ITDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { ITProvider, useITState, useITDispatch };
