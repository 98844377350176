import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { baseUrl } from '../../service/baseUrl';
import { InputGroup } from 'react-bootstrap';
import { AiFillEye } from "react-icons/ai";



const RenderForm = ({
  column,
  index,
  setFormValue,
  inputValue,
  handleFileChange,
  errorFile,
  imagePreview,
  keyValue,
}) => {
  const [intip, setIntip] = useState({
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setIntip({
        ...inputValue,
        showPassword: !intip.showPassword,
    });
  };
  const handlePasswordChange = (prop) => (event) => {
    setIntip({
        ...intip,
        [prop]: event.target.value,
    });
    setFormValue(event, index, column.value)
};
  switch (column.type) {
    case 'single-image':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Control
            required={column.required !== undefined ? column.required : true}
            type="file"
            onChange={handleFileChange}
            accept=".jpg,.gif,.png"
          />
          {true && (
            <p style={{ color: 'red' }} className="mt-2">
              {errorFile}
            </p>
          )}
          <div className="row mt-3 px-4">
            {imagePreview ? (
              <Image src={imagePreview} thumbnail />
            ) : inputValue ? (
              <Image src={`${baseUrl}${inputValue}`} thumbnail />
            ) : null}
          </div>
        </Form.Group>
      );
    case 'image-upload':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Control
            required={column.required !== undefined ? column.required : true}
            type="file"
            onChange={handleFileChange}
          />
          {errorFile && (
            <p style={{ color: 'red' }} className="mt-2">
              {errorFile}
            </p>
          )}
          <div className="row mt-3 px-4">
            {imagePreview ? (
              <Image src={imagePreview} thumbnail />
            ) : inputValue ? (
              <Image src={`${baseUrl}${inputValue}`} thumbnail />
            ) : null}
          </div>
        </Form.Group>
      );
    case 'select':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Select
            required={column.required !== undefined ? column.required : true}
            aria-label="Default select example"
            defaultValue={column.defaultSelect}
            onChange={(e) => column.funcCallback(e)}
          >
            <option value="">Silahkan pilih:</option>
            {column.selectOption.map((item) => {
              return (
                <option key={item} value={item.key}>
                  {item.value}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );
    case 'select-product':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Select
            required={column.required !== undefined ? column.required : true}
            aria-label="Default select example"
            defaultValue={column?.defaultSelect}
            onChange={(e) => column.funcCallbackTech(e)}
          >
            <option value="">Silahkan pilih technology:</option>
            {column.technology.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.technologyName}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );
    case 'select-companies':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Select
            required={column.required !== undefined ? column.required : true}
            aria-label="Default select example"
            defaultValue={column?.defaultSelect}
            onChange={(e) => column.funcCallbackTech(e)}
          >
            <option value="">Silahkan pilih company:</option>
            {column.technology.map((item) => {
              return (
                <option key={item.id} value={item.companyId}>
                  {`${item.companyId}  ${item.companyName}`}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );
    case 'select-machine':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Select
            required={column.required !== undefined ? column.required : true}
            aria-label="Default select example"
            defaultValue={column?.defaultSelect}
            onChange={(e) => column.funcCallbackTech(e)}
          >
            <option value="">Silahkan pilih mesin:</option>
            {column.technology.map((item) => {
              return (
                <option key={item.id} value={item.unitId}>
                  {`${item.unitId}  ${item.machineName}`}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );
    case 'select-status':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Select
            required={column.required !== undefined ? column.required : true}
            aria-label="Default select example"
            defaultValue={column?.defaultSelect}
            onChange={(e) => column.funcCallbackTech(e)}
          >
            <option value="">status tiket</option>
            {column.status.map((item) => {
              return (
                <option key={item.id} value={item}>
                  {`${item}`}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );

    case 'textarea':
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Control
            required={column.required !== undefined ? column.required : true}
            as="textarea"
            rows={column.rowArea !== undefined ? column.rowArea : 3}
            readOnly={column.readOnly}
            placeholder={`${column.label} :`}
            id={column.label}
            name={column.label}
            onChange={(e) => setFormValue(e, index, column.value)}
            defaultValue={inputValue}
          />
          <Form.Control.Feedback type="invalid">
            Data tidak boleh kosong
          </Form.Control.Feedback>
        </Form.Group>
      );
      case 'password':
        return (
          <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                required={column.required !== undefined ? column.required : true}
                type={
                  intip.showPassword
                      ? "text"
                      : "password"
                }
                readOnly={column.readOnly}
                placeholder={column.label}
                id={column.label}
                name={column.label}
                value={intip.password}
                // onChange={(e) => setFormValue(e, index, column.value)}
                onChange={handlePasswordChange("password")}
                // defaultValue={inputValue}
              />
              <InputGroup.Text onClick={handleClickShowPassword} className='kliktombol'><AiFillEye /></InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            Data tidak boleh kosong
          </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
        );
    case 'readonly-tech':
          return (
            <Form.Group className="mb-3" key={keyValue}>
              <Form.Label>{column.label}</Form.Label>
              <Form.Control
                required={column.required !== undefined ? column.required : true}
                type="text"
                readOnly={column.readOnly}
                placeholder={column.label}
                id={column.label}
                name={column.label}
                defaultValue={column.defaultValue}
              />
              <Form.Control.Feedback type="invalid">
                Data tidak boleh kosong
              </Form.Control.Feedback>
            </Form.Group>
          );
    default:
      return (
        <Form.Group className="mb-3" key={keyValue}>
          <Form.Label>{column.label}</Form.Label>
          <Form.Control
            required={column.required !== undefined ? column.required : true}
            type="text"
            readOnly={column.readOnly}
            placeholder={column.label}
            id={column.label}
            name={column.label}
            onChange={(e) => setFormValue(e, index, column.value)}
            defaultValue={inputValue}
          />
          <Form.Control.Feedback type="invalid">
            Data tidak boleh kosong
          </Form.Control.Feedback>
        </Form.Group>
      );
  }
};

export default RenderForm;
