import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ButtonComp from '../button/ButtonComp';
import { addUserApi, updateUserData } from '../../service/customer';

const initialValue = function initial() {
  const initialValue = {
    phoneNumber: '62',
    userName: '',
    email: '',
    company: '',
    jobPotition: '',
    area: '',
    solutions: '',
    customerType: '',
    industry: '',
    vertical: '',
    reasonTransfer: '',
    typeSubmit: '',
    isTransfer: false,
    otherVertical: null,
    purchaseOrRental: null,
    projectDeadline: null,
    competitorBrand: null,
    competitorMachine: null,
    dateSample: null,
    remark: '',
  };
  return initialValue;
};

function FormContent({
  whatIsIt,
  whatToDo,
  formTitle,
  initialData,
  invalidKey,
  setSomethingWrong,
  setShowModalExisting,
  setShowModalSuccess,
  setInitialDataUser,
  salesId,
}) {
  const [formData, setFormData] = useState(
    initialData ? initialData : initialValue
  );
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      const formValidation = e.currentTarget;
      if (formValidation.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
      if (formValidation.checkValidity() === true) {
        if (whatToDo === 'create') {
          response = await addUserApi(formData, salesId);
        }
        if (whatToDo === 'update') {
          response = await updateUserData(formData, formData.phoneNumber);
        }
      }
      if (response.status >= 300) {
        setShowModalExisting(false);
        setSomethingWrong(true);
      }
      if (response.status <= 202) {
        setShowModalExisting(false);
        setShowModalSuccess(true);
        setInitialDataUser({});
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  const changeSolution = () => {
    switch (initialData?.solutions) {
      case 'coding & marking':
        return '1';
      case 'packaging':
        return '2';
      case 'all solution':
        return '3';
      default:
        return '0';
    }
  };
  const solutionsValue = changeSolution();

  const changeCustomerType = () => {
    switch (initialData?.customerType) {
      case 'new':
        return '1';
      case 'existing':
        return '2';
      default:
        return '0';
    }
  };
  const customerTypeValue = changeCustomerType();
  const changeIndustryType = () => {
    switch (initialData?.industry) {
      case 'food & beverage':
        return '1';
      case 'pharmaceutical':
        return '2';
      case 'industrial':
        return '3';
      default:
        return '0';
    }
  };
  const industryTypeValue = changeIndustryType();
  const changeVerticalType = () => {
    if (initialData?.industry === 'food & beverage') {
      switch (initialData?.vertical) {
        case 'baked goods & cereal':
          return '1';
        case 'beverage':
          return '2';
        case 'candy & confectionery':
          return '3';
        case 'dairy':
          return '4';
        case 'egg':
          return '5';
        case 'fruits & vegetables':
          return '6';
        case 'meat & poultry':
          return '7';
        case 'pet food & animal feed':
          return '8';
        case 'salty & snacks':
          return '9';
        case 'other':
          return '10';
        default:
          return '';
      }
    }
    if (initialData?.industry === 'pharmaceutical') {
      switch (initialData?.vertical) {
        case 'cosmetic, personal & home care':
          return '1';
        case 'pharmaceutical & medical devices':
          return '2';
        case 'tobacco':
          return '3';
        case 'other':
          return '10';
        default:
          return '';
      }
    }
    if (initialData?.industry === 'industrial') {
      switch (initialData?.vertical) {
        case 'automotive & aerospace':
          return '1';
        case 'building materials':
          return '2';
        case 'chemicals':
          return '3';
        case 'commercial printing & addressing':
          return '4';
        case 'electrical components & electronics':
          return '5';
        case 'wire, cable & pipe':
          return '6';
        case 'other':
          return '10';
        default:
          return '';
      }
    }
  };
  const verticalTypeValue = changeVerticalType();
  const changePurchaseOrRental = () => {
    switch (initialData?.purchaseOrRental) {
      case 'purchase':
        return '1';
      case 'rental':
        return '2';
      default:
        return '0';
    }
  };
  const purchaseOrRentalValue = changePurchaseOrRental();
  const changeProjectDeadline = () => {
    switch (initialData?.projectDeadline) {
      case '30 days':
        return '1';
      case '60 days':
        return '2';
      case '90 days':
        return '3';
      case '>90 days':
        return '4';
      default:
        return '0';
    }
  };
  const projectDeadlineValue = changeProjectDeadline();

  return (
    <div className="FormContent">
      {whatIsIt === 'salesform' && (
        <h2 className="form-content-header">{formTitle}</h2>
      )}
      {whatIsIt === 'salesform' && (
        <>
          <h5>Phone Number:</h5>
          <p>{`+${initialData.phoneNumber}`}</p>
        </>
      )}
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        // className="form-container"
      >
        {whatIsIt === 'manual' && (
          <Form.Group className="mb-3">
            <Form.Label>Customer Number:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Phone Number"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber ? formData.phoneNumber : ''}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  phoneNumber: e.target.value,
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              {invalidKey}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Visitor Name:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Visitor Name"
            id="userName"
            name="userName"
            value={formData.userName ? formData.userName : ''}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                userName: e.target.value,
              }))
            }
          />
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Company:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Company"
            id="company"
            name="company"
            value={formData.company ? formData.company : ''}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                company: e.target.value,
              }))
            }
          />
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            id="email"
            name="email"
            value={formData.email ? formData.email : ''}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
          />
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        {(whatIsIt === 'salesform' || whatIsIt === 'manual') && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Job Position:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Job Position"
                id="jobPosition"
                name="jobPosition"
                value={formData.jobPosition ? formData.jobPosition : ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    jobPosition: e.target.value,
                  }))
                }
              />
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Area:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Area"
                id="area"
                name="area"
                value={formData.area ? formData.area : ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    area: e.target.value,
                  }))
                }
              />
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Solutions:</Form.Label>
              <Form.Select
                defaultValue={solutionsValue}
                onChange={(e) => {
                  e.currentTarget.value === '1' &&
                    setFormData((prev) => ({
                      ...prev,
                      solutions: 'coding & marking',
                    }));
                  e.currentTarget.value === '2' &&
                    setFormData((prev) => ({
                      ...prev,
                      solutions: 'packaging',
                    }));
                  e.currentTarget.value === '3' &&
                    setFormData((prev) => ({
                      ...prev,
                      solutions: 'all solution',
                    }));
                }}
                aria-label="Default select example"
              >
                <option value="">Silahkan pilih:</option>
                <option value="1">Coding and marking</option>
                <option value="2">Packaging</option>
                <option value="3">All solution</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Application:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Application"
                id="application"
                name="application"
                value={formData.application ? formData.application : ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    application: e.target.value,
                  }))
                }
              />
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Customer type:</Form.Label>
              <Form.Select
                defaultValue={customerTypeValue}
                onChange={(e) => {
                  e.currentTarget.value === '1' &&
                    setFormData((prev) => ({
                      ...prev,
                      customerType: 'new',
                    }));
                  e.currentTarget.value === '2' &&
                    setFormData((prev) => ({
                      ...prev,
                      customerType: 'existing',
                    }));
                }}
                aria-label="Default select example"
              >
                <option value="">Silahkan pilih:</option>
                <option value="1">New</option>
                <option value="2">Existing</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>industry type:</Form.Label>
              <Form.Select
                defaultValue={industryTypeValue}
                onChange={(e) => {
                  e.currentTarget.value === '1' &&
                    setFormData((prev) => ({
                      ...prev,
                      industry: 'food & beverage',
                    }));
                  e.currentTarget.value === '2' &&
                    setFormData((prev) => ({
                      ...prev,
                      industry: 'pharmaceutical',
                    }));
                  e.currentTarget.value === '3' &&
                    setFormData((prev) => ({
                      ...prev,
                      industry: 'industrial',
                    }));
                }}
                aria-label="Default select example"
              >
                <option value="">Silahkan pilih:</option>
                <option value="1">food & beverage</option>
                <option value="2">pharmaceutical</option>
                <option value="3">industrial</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Vertical:</Form.Label>
              {formData.industry === 'food & beverage' && (
                <Form.Select
                  defaultValue={verticalTypeValue}
                  onChange={(e) => {
                    e.currentTarget.value === '1' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'baked goods & cereal',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '2' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'beverage',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '3' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'candy & confectionery',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '4' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'dairy',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '5' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'egg',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '6' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'fruits & vegetables',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '7' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'meat & poultry',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '8' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'pet food & animal feed',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '9' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'salty & snacks',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '10' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'other',
                      }));
                  }}
                  aria-label="Default select example"
                >
                  <option value="">Silahkan pilih:</option>
                  <option value="1">baked goods & cereal</option>
                  <option value="2">beverage</option>
                  <option value="3">candy & confectionery</option>
                  <option value="4">dairy</option>
                  <option value="5">egg</option>
                  <option value="6">fruits & vegetables</option>
                  <option value="7">meat & poultry</option>
                  <option value="8">pet food & animal feed</option>
                  <option value="9">salty & snacks</option>
                  <option value="10">other</option>
                </Form.Select>
              )}
              {formData.industry === 'pharmaceutical' && (
                <Form.Select
                  defaultValue={verticalTypeValue}
                  onChange={(e) => {
                    e.currentTarget.value === '1' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'cosmetic, personal & home care',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '2' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'pharmaceutical & medical devices',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '3' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'tobacco',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '10' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'other',
                      }));
                  }}
                  aria-label="Default select example"
                >
                  <option value="">Silahkan pilih:</option>
                  <option value="1">cosmetic, personal & home care</option>
                  <option value="2">pharmaceutical & medical devices</option>
                  <option value="3">tobacco</option>
                  <option value="10">other</option>
                </Form.Select>
              )}
              {formData.industry === 'industrial' && (
                <Form.Select
                  defaultValue={verticalTypeValue}
                  onChange={(e) => {
                    e.currentTarget.value === '1' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'automotive & aerospace',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '2' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'building materials',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '3' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'chemicals',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '4' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'commercial printing & addressing',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '5' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'electrical components & electronics',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '6' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'wire, cable & pipe',
                        otherVertical: null,
                      }));
                    e.currentTarget.value === '10' &&
                      setFormData((prev) => ({
                        ...prev,
                        vertical: 'other',
                      }));
                  }}
                  aria-label="Default select example"
                >
                  <option value="">Silahkan pilih:</option>
                  <option value="1">automotive & aerospace</option>
                  <option value="2">building materials</option>
                  <option value="3">chemicals</option>
                  <option value="4">commercial printing & addressing</option>
                  <option value="5">electrical components & electronics</option>
                  <option value="6">wire, cable & pipe</option>
                  <option value="10">other</option>
                </Form.Select>
              )}
              <Form.Control.Feedback type="invalid">
                {invalidKey}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}
        {formData.vertical === 'other' && (
          <Form.Group className="mb-3">
            <Form.Label>Other Vertical:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Other Vertical"
              id="otherVertical"
              name="otherVertical"
              value={formData.otherVertical ? formData.otherVertical : ''}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  otherVertical: e.target.value,
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              {invalidKey}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Purchase/Rental:</Form.Label>
          <Form.Select
            defaultValue={purchaseOrRentalValue}
            onChange={(e) => {
              e.currentTarget.value === '1' &&
                setFormData((prev) => ({
                  ...prev,
                  purchaseOrRental: 'purchase',
                }));
              e.currentTarget.value === '2' &&
                setFormData((prev) => ({
                  ...prev,
                  purchaseOrRental: 'rental',
                }));
            }}
            aria-label="Default select example"
          >
            <option value="">Silahkan pilih:</option>
            <option value="1">Purchase</option>
            <option value="2">Rental</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Project Deadline:</Form.Label>
          <Form.Select
            defaultValue={projectDeadlineValue}
            onChange={(e) => {
              e.currentTarget.value === '1' &&
                setFormData((prev) => ({
                  ...prev,
                  projectDeadline: '30 days',
                }));
              e.currentTarget.value === '2' &&
                setFormData((prev) => ({
                  ...prev,
                  projectDeadline: '60 days',
                }));
              e.currentTarget.value === '3' &&
                setFormData((prev) => ({
                  ...prev,
                  projectDeadline: '90 days',
                }));
              e.currentTarget.value === '4' &&
                setFormData((prev) => ({
                  ...prev,
                  projectDeadline: '>90 days',
                }));
            }}
            aria-label="Default select example"
          >
            <option value="">Silahkan pilih:</option>
            <option value="1">30 days</option>
            <option value="2">60 days</option>
            <option value="3">90 days</option>
            <option value="4">more than 90 days</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Competitor Brand:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Competitor Brand"
            id="competitorBrand"
            name="competitorBrand"
            value={formData.competitorBrand ? formData.competitorBrand : ''}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                competitorBrand: e.target.value,
              }))
            }
          />
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Competitor Machine:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Competitor Machine"
            id="competitorMachine"
            name="competitorMachine"
            value={formData.competitorMachine ? formData.competitorMachine : ''}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                competitorMachine: e.target.value,
              }))
            }
          />
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Remark:</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder=""
            id="remark"
            name="remark"
            value={formData.remark ? formData.remark : ''}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                remark: e.target.value,
              }))
            }
          />
          <Form.Control.Feedback type="invalid">
            {invalidKey}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="button-on-form">
          {whatToDo === 'update' && (
            <ButtonComp buttonType={'submit'} primary>
              Update User
            </ButtonComp>
          )}
          {whatToDo === 'create' && (
            <ButtonComp buttonType={'submit'} primary>
              Create User
            </ButtonComp>
          )}
        </div>
      </Form>
    </div>
  );
}

export default FormContent;
