import { Route, Routes } from 'react-router-dom';
import SalesDashboard from '../pages/SalesDashboard/SalesDashboard';
import { useEffect, useState } from 'react';
import LoginPage from '../pages/Login/LoginPage';
// import LoginAdminPage from '../pages/Admin/LoginAdmin/LoginAdminPage';
import ProductDashboard from '../pages/Admin/ProductDashboard/ProductDashboard';
import { ProductProvider } from '../pages/Admin/ProductDashboard/ProviderProduct';
import LoginAdminCSPage from '../pages/AdminCS/LoginAdminCSPage';
import CSDashboard from '../pages/AdminCS/CSDashboard/CSDashboard';
import { CSProvider } from '../pages/AdminCS/CSDashboard/ProviderCS';
// import LoginAdminITPage from '../pages/AdminIT/LoginAdminITPage';
import ITDashboard from '../pages/AdminIT/ITDashboard/ITDashboard';
import { ITProvider } from '../pages/AdminIT/ITDashboard/ProviderIT';
import { AllUsersProvider } from '../pages/AdminIT/AllUserMaster/ProviderAllUsers';
import SalesCustomerCount from '../pages/SalesCustomerCount/SalesCustomerCount';
import Layout from '../components/Header/Layout';
import CreateFirstUser from '../pages/AdminIT/AllUserMaster/CreateFirstUser';

const Router = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [userLogin, setUserLogin] = useState({});

  useEffect(() => {
    setIsAuth(localStorage.getItem('logged'));
  }, []);

  return (
    <ITProvider>
      <AllUsersProvider>
        <Routes>
        <Route
            path="/firstsignup"
            element={
              <CreateFirstUser />
            }
          />
          <Route
            path="/"
            element={
              <LoginPage setIsAuth={setIsAuth} setUserLogin={setUserLogin} />
            }
          />
          <Route
            path="/sales"
            element={<Layout><SalesDashboard userLogin={userLogin} /></Layout>}
          />
          <Route path="/it-dashboard" element={<Layout><ITDashboard /></Layout>} />
          <Route
            path="/product-dashboard"
            element={
              <ProductProvider>
                <Layout>
                  <ProductDashboard />
                </Layout>
              </ProductProvider>
            }
          />
          <Route
            path="/dashboard-management"
            element={<Layout>
              <SalesCustomerCount />
              </Layout>
            }
          />
          {/* <Route path="/login-admin" element={<LoginAdminPage />} /> */}
          <Route path="/login-admin-cs" element={<Layout>
            <LoginAdminCSPage />
            </Layout>
            } />
          <Route
            path="/cs-dashboard"
            element={
              <CSProvider>
                <Layout>
                <CSDashboard />
                </Layout>
              </CSProvider>
            }
          />
          {/* <Route path="/login-admin-it" element={<LoginAdminITPage />} /> */}
        </Routes>
      </AllUsersProvider>
    </ITProvider>
  );
};

export default Router;
