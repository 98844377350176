import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import { useMachineDispatch, useMachineState } from './ProviderMachine';
import { dispatchWithTimeout, getAllMachines } from './ActionDispatch';
import RenderForm from '../../../components/RenderForm/RenderForm';

const BodyModalCreate = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);
  const dispatch = useMachineDispatch();
  const state = useMachineState();
  const globalState = useCSState();
  const globaldispatch = useCSDispatch();
  const key = globalState.key;
  const createValue = state.valueCreate;
  const listColumn = [
    {
      id: 1,
      label: 'Machine Name',
      value: 'machineName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'Unit ID',
      value: 'unitId',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'Company ID',
      value: 'companyId',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 4,
      label: 'Barcode',
      value: 'barcode',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 5,
      label: 'Image Height',
      value: 'imageHeight',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 6,
      label: 'Image Width',
      value: 'imageWidth',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 7,
      label: 'Image (max upload 2mb)',
      value: 'imageTechnology',
      type: 'single-image',
      readOnly: false,
    },
  ];

  const setFormValue = (e, index, column) => {
    const copyObj = Object.assign({}, createValue, {
      [column]: e.target.value,
    });
    dispatch({
      type: `SET_VALUE_CREATE_${key}`,
      payload: copyObj,
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size <= 2 * 1024 * 1024) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
        setError(null);
      } else {
        setError('File size exceeds 2MB.');
        setSelectedFile(null);
        setImagePreview(null);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    try {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        if (selectedFile) {
          formData.append('attachment', selectedFile);
        }

        for (const key in createValue) {
          const value = createValue[key];
          if (value !== undefined) {
            formData.append(key, value);
          }
        }

        const url = `machines`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios
          .post(`${baseUrl}/${url}`, formData, {
            headers: { Authorization: decSecret },
          })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });

        if (response.status === 200) {
          dispatch(getAllMachines(dispatch));
          dispatch({
            type: `SET_VALUE_CREATE_${key}`,
            payload: {},
          });
          dispatchWithTimeout(
            globaldispatch,
            `SET_MODAL_CREATE_${key}`,
            false,
            500
          );
          dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        globaldispatch({
          type: `SET_FAILED_MESSAGE`,
          payload: `${response.data.message}`,
        });
        globaldispatch({ type: `SET_MODAL_FAILED`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <section>
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="pe-2"
      >
        {listColumn.map((column, index) => {
          const inputValue = createValue[column.value] || '';
          return (
            <RenderForm
              keyValue={column.id}
              key={column.id}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              errorFile={error}
              imagePreview={imagePreview}
              handleFileChange={handleFileChange}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Create</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};

export default BodyModalCreate;
