import { createContext, useReducer, useContext } from 'react';

// Step 1: Create a Context
const UserCompanyStateContext = createContext();
const UserCompanyDispatchContext = createContext();

// Step 2: Create a reducer function
const UserCompanyReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA_USERCOMPANY':
      return { ...state, data: action.payload };
    case 'SET_DATA_COMPANY':
      return { ...state, dataCompany: action.payload };
    case 'SET_LOADING_USERCOMPANY':
      return { ...state, loading: action.payload };
    case 'SET_MODAL_CREATE_USERCOMPANY':
      return { ...state, showModalCreate: action.payload };
    case 'SET_MODAL_EDIT_USERCOMPANY':
      return { ...state, showModalEdit: action.payload };
    case 'SET_MODAL_DELETE_USERCOMPANY':
      return { ...state, showModalDelete: action.payload };
    case 'SET_VALUE_CREATE_USERCOMPANY':
      return { ...state, valueCreate: action.payload };
    case 'SET_VALUE_EDIT_USERCOMPANY':
      return { ...state, valueEdit: action.payload };
    case 'SET_VALUE_DELETE_USERCOMPANY':
      return { ...state, valueDelete: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const UserCompanyProvider = ({ children }) => {
  const initialState = {
    data: [],
    dataCompany: [],
    loading: true,
    showModalCreate: false,
    showModalEdit: false,
    showModalDelete: false,
    valueCreate: {
      companyName: '',
      companyId: '',
    },
    valueEdit: {},
    valueDelete: {},
  };
  const [state, dispatch] = useReducer(UserCompanyReducer, initialState);

  return (
    <UserCompanyStateContext.Provider value={state}>
      <UserCompanyDispatchContext.Provider value={dispatch}>
        {children}
      </UserCompanyDispatchContext.Provider>
    </UserCompanyStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useUserCompanyState = () => {
  const context = useContext(UserCompanyStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useUserCompanyDispatch = () => {
  const context = useContext(UserCompanyDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { UserCompanyProvider, useUserCompanyState, useUserCompanyDispatch };
