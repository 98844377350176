import { useEffect, useState } from "react";
import MainTableCustom from "../../../components/TableComp/MainTableCustom";
import ModalComp from "../../../components/modal/ModalComp";
import {
  useProductDispatch,
  useProductState,
} from "../ProductDashboard/ProviderProduct";
import { dispatchWithTimeout, getAllProduct } from "../ProductDashboard/ActionDispatch";
import BodyModalCreate from "./BodyModalCreate";
import BodyModalEdit from "./BodyModalEdit";
import BodyModalDelete from "./BodyModalDelete";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import axios from "axios";
import { baseUrl } from "../../../service/baseUrl";


const TechnologyMaster = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const state = useProductState();
  const loading = state.loadingProduct;
  const dataProduct = state.dataProduct;
  const dispatch = useProductDispatch();
  const approle = localStorage.getItem('approle');

  const handleUpdateSort = async(id1,id2)=>{
    const data = {
      id1, id2
    }
    const url = `v1/technology/technlogy-master/updateurutan`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .post(`${baseUrl}/${url}`, data , {
        headers: { Authorization: decSecret },
      })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e.response;
      });
    if (response.status === 200) {
      const options = approle ?? null;
      dispatch(getAllProduct(dispatch, options));
      dispatchWithTimeout(
        dispatch,
        "SET_MODAL_EDIT_TECHNOLOGY",
        false,
        500
      );
      return;
    }
    dispatch({ type: "SET_MODAL_FAILED", payload: true });
    return;
  }
    

  const fetchData = async () => {
    const options = approle ?? null;
    dispatch(getAllProduct(dispatch, options));
  };

  const tableColumn = [
    {
      id: 1,
      value: "technologyName",
      label: "Name",
      type: "string",
      search: true,
    },
    {
      id: 2,
      value: "imageTechnology",
      label: "Image",
      type: "image",
    },
    {
      id: 3,
      value: "action",
      label: "Action",
      type: "button",
      search: false,
      renderButton: [
        {
          type: 'edit',
          name: [<BiEdit />],
          handler: (e) => {
            dispatch({ type: "SET_MODAL_EDIT_TECHNOLOGY", payload: true });
            dispatch({ type: "SET_VALUE_EDIT_TECHNOLOGY", payload: e });
          },
        },
        {
          type: 'hapus',
          name: [<BiTrash />],
          handler: (e) => {
            dispatch({ type: "SET_MODAL_DELETE_TECHNOLOGY", payload: true });
            dispatch({ type: "SET_VALUE_EDIT_TECHNOLOGY", payload: e });
          },
        },
      ],
    },
    {
      id: 4,
      value: "sortDisplay",
      label: "Sort",
      type: "sort",
      renderButton: [
        {
          type: "up",
          name: [<FaArrowAltCircleUp />],
          handler: (current, prevRow, nextRow) => {
            const prevId = prevRow.id;
            const currentId =  current.id;
            handleUpdateSort(currentId, prevId)
          },
        },
        { 
          type: "down",
          name: [<FaArrowAltCircleDown />],
          handler: (current, prevRow, nextRow) => {
            console.log(current)
            const currentId =  current.id;
            const nextId =  nextRow.id;
            handleUpdateSort(currentId, nextId)
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="search_wrap mt-4 py-2">
        <input
          type="text"
          placeholder="Search Here"
          required
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>

      <MainTableCustom
        header={tableColumn}
        data={dataProduct}
        loading={loading}
        searchTerm={searchTerm}
      />

      <ModalComp
        show={state.showModalCreate}
        onHide={() =>
          dispatch({ type: "SET_MODAL_CREATE_TECHNOLOGY", payload: false })
        }
        ModalHeader={"Technology data:"}
        buttonText={{ primary: "Create" }}>
        <BodyModalCreate />
      </ModalComp>

      <ModalComp
        show={state.showModalEdit}
        onHide={() =>
          dispatch({ type: "SET_MODAL_EDIT_TECHNOLOGY", payload: false })
        }
        ModalHeader={"Technology data:"}
        buttonText={{ primary: "Create" }}>
        <BodyModalEdit />
      </ModalComp>

      <ModalComp
        show={state.showModalDeleteTechnology}
        ModalHeader={`Confirm Delete: ${state.valueEditTech?.technologyName}`}
        onHide={() =>
          dispatch({ type: "SET_MODAL_DELETE_TECHNOLOGY", payload: false })
        }>
        <BodyModalDelete />
      </ModalComp>

    </div>
  );
};

export default TechnologyMaster;
