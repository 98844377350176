import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import { userRoles } from '../../../utils/listRole';

const whichOne = 'SALESUSERS'

const getSalesUsers = async (dispatch) => {
  dispatch({ type: `SET_LOADING_${whichOne}`, payload: true });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'userapp/getwhere';
  const options = {where: {role: userRoles.role3}};
  const response = await axios({
    method: 'post',
    url: `${baseUrl}/${url}`,
    data: options,
    headers: { Authorization: decSecret },
  }).catch((e) => {
      console.log(e);
    });

  dispatch({ type: `SET_DATA_${whichOne}`, payload: response.data.data });
  setTimeout(() => {
    dispatch({ type: `SET_LOADING_${whichOne}`, payload: false });
  }, [500]);
};

function dispatchWithTimeout(dispatch, actionType, payload, delay) {
  setTimeout(() => {
    dispatch({ type: actionType, payload });
  }, delay);
}

export { getSalesUsers, dispatchWithTimeout };
