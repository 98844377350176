import { createContext, useReducer, useContext } from 'react';

// create context/ ganti nama sesuai context yang sedang dibuat
const ITUsersStateContext = createContext();
const ITUsersDispatchContext = createContext();
// state names / ganti dengan nama penanda dari states yang dibutuhkan
const stateGroup = 'ITUSERS'
// Create a reducer function
const ITUsersReducer = (state, action) => {
  switch (action.type) {
    case `SET_DATA_${stateGroup}`:
      return { ...state, data: action.payload };
    case `SET_LOADING_${stateGroup}`:
      return { ...state, loading: action.payload };
    case `SET_MODAL_EDIT_${stateGroup}`:
      return { ...state, showModalEdit: action.payload };
    case `SET_VALUE_EDIT_${stateGroup}`:
      return { ...state, valueEdit: action.payload };
    default:
      return state;
  }
};

// Create a provider component
const ITUsersProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalEdit: false,
    valueEdit: {},
  };
  const [state, dispatch] = useReducer(ITUsersReducer, initialState);

  return (
    <ITUsersStateContext.Provider value={state}>
      <ITUsersDispatchContext.Provider value={dispatch}>
        {children}
      </ITUsersDispatchContext.Provider>
    </ITUsersStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state / ganti nama sesuai kebutuhan
const useITUsersState = () => {
  const context = useContext(ITUsersStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch / ganti nama sesuai kebutuhan
const useITUsersDispatch = () => {
  const context = useContext(ITUsersDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};
// ganti nama export susai function di atas
export { ITUsersProvider, useITUsersState, useITUsersDispatch };
