import React, { useEffect, useState } from 'react';
import ButtonComp from '../../components/button/ButtonComp';
import axios from 'axios';
import ModalComp from '../../components/modal/ModalComp';
import FormContent from '../../components/formContent/FormContent';
import { BiEdit, BiTrash } from "react-icons/bi";
import Form from 'react-bootstrap/Form';
// import UserTable from '../../components/UserTable/UserTable';
import UserDetailTable from '../../components/UserDetailTable/UserDetailTable';
import { baseUrl } from '../../service/baseUrl';
import MainTableCustom from '../../components/TableComp/MainTableCustom';
import AccountDetailModal from '../../components/AccountDetailModal/AccountDetailModal';
import { useITDispatch } from '../AdminIT/ITDashboard/ProviderIT';
import {
  SalesAppRole2,
  salesDashboardAllowed,
  userRoles,
} from '../../utils/listRole';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';

function SalesDashboard({ userLogin }) {
  const [userStatus, setUserStatus] = useState(false);
  const [userDataSales, setUserDataSales] = useState([]);
  const [showModalFailed, setShowModalFailed] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalUpdateForm, setShowModalUpdateForm] = useState(false);
  const [showModalCreateForm, setShowModalCreateForm] = useState(false);
  const [showModalUserDetail, setShowModalUserDetail] = useState(false);
  const [showModalTransfer, setShowModalTransfer] = useState(false);
  const [initialDataUser, setInitialDataUser] = useState({});
  const [currentSalesId, setCurrentSalesId] = useState('');
  const [reasonForTransfer, setReasonForTransfer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const salesId = localStorage.getItem('salesid');
  const salesName = localStorage.getItem('salesname');
  const [customerCount, setCustomerCount] = useState();
  const userRole = localStorage.getItem('role');
  const userAppRole = localStorage.getItem('approle');
  const userId = localStorage.getItem('userID');
  const navigate = useNavigate();

  const dispatch = useITDispatch();
  const userData = JSON.parse(localStorage.getItem('userData'));

  const handleShowAccountModal = (what) => {
    dispatch({ type: `SET_MODAL_ACCOUNT_${what}`, payload: true });
    dispatch({ type: `SET_VALUE_ACCOUNT_IT`, payload: userData[0] });
  };
  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate('/');
    }, 500);
  };
  const handleTransfer = async (phoneNum, curSales, reason) => {
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const form = {
      isTransfer: true,
      currentSales: curSales,
      reasonTransfer: reason,
    };
    const response = await axios
      .patch(`${baseUrl}/user/${phoneNum}`, form, {
        headers: { Authorization: decSecret },
      })
      .catch((e) => {
        setShowModalFailed(true);
      });
    if (response.status === 201) {
      setShowModalTransfer(false);
      setInitialDataUser({});
      setCurrentSalesId('');
      setReasonForTransfer('');
      setShowModalSuccess(true);
    }
  };

  const getAllUserAllSales = async () => {
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .get(`${baseUrl}/user`, { headers: { Authorization: decSecret } })
      .catch((e) => {
        setShowModalFailed(true);
      });
    if (response.status === 201) {
      setShowModalUpdateForm(false);
    }
    const customerDataWithoutTest = response.data.data.users.filter((data) => {
      return data.currentSales !== '@SalesTest';
    });
    setCustomerCount(customerDataWithoutTest.length);
    setUserDataSales(customerDataWithoutTest);
    return response;
  };
  const getAllUserAllSalesWithQuery = async (query) => {
    setLoading(true);
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .get(`${baseUrl}/user`, {
        params: query,
        headers: { Authorization: decSecret },
      })
      .catch((e) => {
        setShowModalFailed(true);
      });
    setLoading(false);
    const customerDataWithoutTest = response.data.data.users.filter((data) => {
      return data.currentSales !== '@SalesTest';
    });
    setUserDataSales(customerDataWithoutTest);
    return response;
  };
  const getUserDataSales = async (salesId) => {
    setLoading(true);
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .get(`${baseUrl}/user/get-specific-sales-user-null/${salesId}`, {
        headers: { Authorization: decSecret },
      })
      .catch((e) => {
        setShowModalFailed(true);
      });
    setLoading(false);
    setUserDataSales(response.data.data.user);
  };
  const getUserDataWithQuery = async (query) => {
    setLoading(true);
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .get(`${baseUrl}/user/get-specific-sales-user-null/${salesId}`, {
        params: query,
        headers: { Authorization: decSecret },
      })
      .catch((e) => {
        setShowModalFailed(true);
      });
    setLoading(false);
    setUserDataSales(response.data.data.user);
    return response;
  };
  const getTodayCount = async () => {
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .get(`${baseUrl}/user/get-total-sales-today/${salesId}`, {
        headers: { Authorization: decSecret },
      })
      .catch((e) => {
        setShowModalFailed(true);
      });
    setCustomerCount(response.data.total);
  };

  const tableColumn = [
    {
      value: 'createdAt',
      label: 'date',
      type: 'date',
      search: true,
    },
    {
      value: 'createdAt',
      label: 'time',
      type: 'time',
      search: true,
    },
    {
      value: 'userName',
      label: 'name',
      type: 'string',
      search: true,
    },
    {
      value: 'company',
      label: 'company',
      type: 'string',
      search: true,
    },
    {
      value: 'phoneNumber',
      label: 'Phone Number',
      type: 'phone-user',
      search: true,
      func: (e) => {
        setInitialDataUser(e);
        setShowModalUserDetail(true);
      },
    },
    {
      value: 'action',
      label: 'Action',
      type: 'custom-user',
      search: false,
      renderButton: [
        {
          type: 'edit',
          name: [<BiEdit />],
          salesId: salesId,
          handler: (e) => {
            setInitialDataUser(e);
            setShowModalUpdateForm(true);
          },
        },
      ],
    },
  ];
  const fetchUser = async () => {
    const url = `userapp/getonewhere`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decsecret = window.btoa(rahasia);
    const response = await axios({
      method: 'post',
      url: `${baseUrl}/${url}`,
      data: {
        where: {
          userID: userId,
        },
      },
      headers: { Authorization: decsecret },
    })
      .then((response) => {
        if (response.data.data) {
          if (
            response.data.data.role === userRoles.role1 ||
            response.data.data.role === userRoles.role3
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
      .catch((e) => {
        return e.response;
      });
    return response;
  };

  useEffect(() => {
    async function fetch() {
      const userStatus = await fetchUser();
      return userStatus;
    }
    fetch()
      .then((result) => {
        if (result) {
          const isAllowed = salesDashboardAllowed.includes(userRole);
          if (!isAllowed) {
            navigate('/');
          } else {
            if (
              salesId === '@00' ||
              userRole === userRoles.role1 ||
              (userRole === userRoles.role3 &&
                userAppRole === SalesAppRole2.approle2)
            ) {
              getAllUserAllSales();
            } else {
              getUserDataSales(salesId);
              getTodayCount();
            }
          }
          return;
        } else {
          localStorage.clear();
          setTimeout(() => {
            navigate('/');
          }, 500);
        }
      })
      .catch((e) => {
        return;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesId, showModalSuccess]);

  return (
    <div className="ProductDashboard">
      <div className="sales-container d-flex align-items-center justify-content-between">
        <div>
          <p>Sales no: {salesId}</p>
          <p>Sales name: {salesName} </p>
          <p>Total customer count: {customerCount}</p>
        </div>
        
        <DropdownButton variant='light' title="Setting">
          <Dropdown.Item onClick={() => handleShowAccountModal('IT')}>My account</Dropdown.Item>
          {userRole === userRoles.role1 ||
          (userRole === userRoles.role3 &&
            userAppRole === SalesAppRole2.approle2) ?
          (
          <Dropdown.Item onClick={() => navigate('/dashboard-management')}>Management</Dropdown.Item>
          ): null }
          <Dropdown.Item onClick={() => setShowModalCreateForm(true)}>Create customer</Dropdown.Item>
          <Dropdown.Item onClick={() => handleLogout()}>Log out</Dropdown.Item>
        </DropdownButton>
      </div>


      <div className="search_wrap py-2">
        <input
          type="text"
          placeholder="Search Here"
          required
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>

      <div className='select-category'>
        <Form.Select
          onChange={(e) => {
            if (salesId === '@00') {
              e.currentTarget.value === '0' && getAllUserAllSales();
              e.currentTarget.value === '1' &&
                getAllUserAllSalesWithQuery({ userName: 'null' });
              e.currentTarget.value === '2' &&
                getAllUserAllSalesWithQuery({ userName: 'not null' });
              e.currentTarget.value === '3' &&
                getAllUserAllSalesWithQuery({ typeSubmit: 'create' });
              e.currentTarget.value === '4' &&
                getAllUserAllSalesWithQuery({ typeSubmit: 'update' });
              e.currentTarget.value === '5' &&
                getAllUserAllSalesWithQuery({ isTransfer: true });
              e.currentTarget.value === '6' &&
                getAllUserAllSalesWithQuery({ followUp: true });
            } else {
              e.currentTarget.value === '0' && getUserDataSales(salesId);
              e.currentTarget.value === '1' &&
                getUserDataWithQuery({ userName: 'null' });
              e.currentTarget.value === '2' &&
                getUserDataWithQuery({ userName: 'not null' });
              e.currentTarget.value === '3' &&
                getUserDataWithQuery({ typeSubmit: 'create' });
              e.currentTarget.value === '4' &&
                getUserDataWithQuery({ typeSubmit: 'update' });
              e.currentTarget.value === '5' &&
                getUserDataWithQuery({ isTransfer: true });
              e.currentTarget.value === '6' &&
                getUserDataWithQuery({ followUp: true });
            }
          }}
          aria-label="Default select example"
        >
          <option value="0">Show all customer data</option>
          <option value="1">Undefined customer name only</option>
          <option value="2">Defined customer name only</option>
          <option value="3">Created by sales</option>
          <option value="4">Created by system</option>
          <option value="5">Transfer customer</option>
          <option value="6">Need follow up</option>
        </Form.Select>
      </div>
      <MainTableCustom
        header={tableColumn}
        data={userDataSales}
        loading={loading}
        searchTerm={searchTerm}
      />
      {/* <UserTable
        userDataSales={userDataSales}
        searchTerm={searchTerm}
        setInitialDataUser={setInitialDataUser}
        setShowModalUserData={setShowModalUserDetail}
        salesId={salesId}
        setShowModalForm={setShowModalUpdateForm}
        loading={loading}
      /> */}

      <ModalComp
        show={showModalCreateForm}
        onHide={() => setShowModalCreateForm(false)}
        ModalHeader={'Customer data:'}
        buttonText={{ primary: 'Create' }}
        primaryFunc={() => setShowModalCreateForm(false)}
      >
        <FormContent
          whatToDo={'create'}
          invalidKey="data tidak boleh kosong"
          whatIsIt={'manual'}
          salesId={salesId}
          setSomethingWrong={setShowModalFailed}
          setShowModalExisting={setShowModalCreateForm}
          setShowModalSuccess={setShowModalSuccess}
          setInitialDataUser={setInitialDataUser}
        />
      </ModalComp>
      <ModalComp
        show={showModalUserDetail}
        onHide={() => {
          setShowModalUserDetail(false);
        }}
        ModalHeader={'Customer data:'}
        buttonText={{ primary: 'close' }}
        primaryFunc={() => setShowModalUserDetail(false)}
      >
        <UserDetailTable
          initialDataUser={initialDataUser}
          setShowModalUserDetail={setShowModalUserDetail}
          setShowModalUpdateForm={setShowModalUpdateForm}
          setShowModalTransfer={setShowModalTransfer}
          salesId={salesId}
        />
      </ModalComp>
      <ModalComp
        show={showModalUpdateForm}
        onHide={() => {
          setShowModalUpdateForm(false);
        }}
        ModalHeader={'Customer data:'}
        buttonText={{ primary: 'update' }}
        primaryFunc={() => setShowModalUpdateForm(false)}
      >
        <FormContent
          whatToDo={'update'}
          invalidKey="data tidak boleh kosong"
          initialData={initialDataUser}
          whatIsIt={'salesform'}
          setSomethingWrong={setShowModalFailed}
          setShowModalExisting={setShowModalUpdateForm}
          setShowModalSuccess={setShowModalSuccess}
          setInitialDataUser={setInitialDataUser}
        />
      </ModalComp>
      <ModalComp
        show={showModalTransfer}
        onHide={() => setShowModalTransfer(false)}
        ModalHeader={'Transfer customer'}
        buttonText={{ primary: 'close' }}
        primaryFunc={() => setShowModalTransfer(false)}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => {
            const formValidation = e.currentTarget;
            if (formValidation.checkValidity() === false) {
              e.preventDefault();
              e.stopPropagation();
            }
            setValidated(true);
          }}
          className="form-container"
        >
          <Form.Group className="mb-3">
            <Form.Label>Sales ID you like to transfer to?</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Sales Id"
              id="currentSales"
              name="currentSales"
              value={currentSalesId}
              onChange={(e) => setCurrentSalesId(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Cannot be empty!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Reason for transfer</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Reason for transfer"
              id="reasonTransfer"
              name="reasonTransfer"
              value={reasonForTransfer}
              onChange={(e) => setReasonForTransfer(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Cannot be empty!
            </Form.Control.Feedback>
          </Form.Group>
          <ButtonComp
            clickFunc={(e) => {
              e.preventDefault();
              console.log(reasonForTransfer);
              handleTransfer(
                initialDataUser.phoneNumber,
                currentSalesId,
                reasonForTransfer
              );
            }}
            buttonType={'submit'}
            primary
          >
            Transfer
          </ButtonComp>
        </Form>
      </ModalComp>
      <ModalComp
        show={showModalFailed}
        onHide={() => setShowModalFailed(false)}
        ModalHeader={'sorry'}
        buttonText={{ primary: 'close' }}
        primaryFunc={() => setShowModalFailed(false)}
      >
        something when wrong, try again later
      </ModalComp>
      <ModalComp
        show={showModalSuccess}
        onHide={() => {
          setShowModalSuccess(false);
          setInitialDataUser({});
        }}
        ModalHeader={'updated!'}
        buttonText={{ primary: 'close' }}
        primaryFunc={() => setShowModalSuccess(false)}
      >
        user successfully updated!
      </ModalComp>
      <AccountDetailModal
        ModalHeader={'My Account'}
        buttonText={{ primary: 'update' }}
      />
    </div>
  );
}

export default SalesDashboard;
