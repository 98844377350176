import axios from 'axios';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import { baseUrl } from '../../../service/baseUrl';
// global
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// company
import { useCompanyState } from '../CompanyMaster/ProviderCompany';
// component
import { useMachineDispatch, useMachineState } from './ProviderMachine';
import { getAllMachines, dispatchWithTimeout } from './ActionDispatch';

import RenderForm from '../../../components/RenderForm/RenderForm';

const BodyModalEdit = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);
  // global state
  const globalState = useCSState();
  const globaldispatch = useCSDispatch();
  const key = globalState.key;
  // company state
  const CompanyState = useCompanyState();
  const stateCompanies = CompanyState.data;
  // components state
  const dispatch = useMachineDispatch();
  const state = useMachineState();
  const editValue = state.valueEdit;

  const listColumn = [
    {
      id: 1,
      label: 'Machine Name',
      value: 'machineName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'Unit ID',
      value: 'unitId',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'Company ID',
      value: 'companyId',
      type: 'select-companies',
      required: false,
      defaultSelect: editValue.companyId,
      technology: [...stateCompanies],
      funcCallbackTech: (e) => {
        const copyObj = Object.assign({}, editValue, {
          companyId: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_EDIT_${key}`,
          payload: copyObj,
        });
      },
    },
    {
      id: 4,
      label: 'Barcode',
      value: 'barcode',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 5,
      label: 'Image Height',
      value: 'imageHeight',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 6,
      label: 'Image Width',
      value: 'imageWidth',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 7,
      label: 'Image (max upload 2mb)',
      value: 'image',
      type: 'image-upload',
      required: false,
      readOnly: false,
    },
  ];

  const setFormValue = (e, index, column) => {
    // Create a copy of the columns
    const copyObj = Object.assign({}, editValue, { [column]: e.target.value });

    dispatch({
      type: `SET_VALUE_EDIT_${key}`,
      payload: copyObj,
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size <= 2 * 1024 * 1024) {
        setImageChange(true);
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
        setError(null);
      } else {
        setError('File size exceeds 2MB.');
        setSelectedFile(null);
        setImagePreview(null);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        if (selectedFile) {
          formData.append('attachment', selectedFile);
          formData.append('imageUpdate', imageChange);
        }
        for (const key in editValue) {
          const value = editValue[key];
          if (value !== undefined) {
            formData.append(key, value);
          }
        }
        const url = `machines/`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios({
          method: 'patch',
          url: `${baseUrl}/${url}`,
          data: formData,
          headers: { Authorization: decSecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 201) {
          dispatch(getAllMachines(dispatch));
          dispatchWithTimeout(dispatch, `SET_MODAL_EDIT_${key}`, false, 500);
          dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        globaldispatch({ type: `SET_MODAL_FAILED`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
      return;
    }
  };

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="pe-2"
      >
        {listColumn.map((column, index) => {
          const inputValue = editValue[column.value] || '';
          return (
            <RenderForm
              key={column.value}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              errorFile={error}
              imagePreview={imagePreview}
              handleFileChange={handleFileChange}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Save</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};
export default BodyModalEdit;
