import { createContext, useReducer, useContext } from 'react';

// Step 1: Create a Context
const MachineStateContext = createContext();
const MachineDispatchContext = createContext();

// Step 2: Create a reducer function
const MachineReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA_MACHINE':
      return { ...state, data: action.payload };
    case 'SET_LOADING_MACHINE':
      return { ...state, loading: action.payload };
    case 'SET_MODAL_CREATE_MACHINE':
      return { ...state, showModalCreate: action.payload };
    case 'SET_MODAL_EDIT_MACHINE':
      return { ...state, showModalEdit: action.payload };
    case 'SET_MODAL_DELETE_MACHINE':
      return { ...state, showModalDelete: action.payload };
    case 'SET_VALUE_CREATE_MACHINE':
      return { ...state, valueCreate: action.payload };
    case 'SET_VALUE_EDIT_MACHINE':
      return { ...state, valueEdit: action.payload };
    case 'SET_VALUE_DELETE_MACHINE':
      return { ...state, valueDelete: action.payload };
    case 'SET_MODAL_SUCCESS':
      return { ...state, showModalSuccess: action.payload };
    case 'SET_MODAL_FAILED':
      return { ...state, showModalFailed: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const MachineProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalCreate: false,
    showModalEdit: false,
    showModalDelete: false,
    valueCreate: {
      companyName: '',
      companyId: '',
    },
    valueEdit: {},
    valueDelete: {},
    showModalSuccess: false,
    showModalFailed: false,
  };
  const [state, dispatch] = useReducer(MachineReducer, initialState);

  return (
    <MachineStateContext.Provider value={state}>
      <MachineDispatchContext.Provider value={dispatch}>
        {children}
      </MachineDispatchContext.Provider>
    </MachineStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useMachineState = () => {
  const context = useContext(MachineStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useMachineDispatch = () => {
  const context = useContext(MachineDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { MachineProvider, useMachineState, useMachineDispatch };
