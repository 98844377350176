import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
// components
import ModalComp from '../../../components/modal/ModalComp';
import AllUsersMaster from '../AllUserMaster/AllUsersMaster';
// context
import { useITDispatch, useITState } from './ProviderIT';
import { AllUsersProvider } from '../AllUserMaster/ProviderAllUsers';
import { ITUsersProvider } from '../ITRegisteredUsers/ProviderITUsers';

import UserRegisteredSales from '../UserRegisteredSales/SalesMaster';
import { SalesUsersProvider } from '../UserRegisteredSales/ProviderSalesUsers';
import UserRegisteredCMS from '../CMSRegisteredUsers/CMSMaster';
import { CMSUsersProvider } from '../CMSRegisteredUsers/ProviderCMSUsers';
import { itDashboardAllowed } from '../../../utils/listRole';
import UserRegisteredIT from '../ITRegisteredUsers/ITMaster';
import BodyModalAccount from '../../../components/AccountModalBody/BodyModalAccount';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { PhoneNumberListProvider } from '../PhoneNumberList/ProviderPhoneNumberListUsers';
import PhoneNumberList from '../PhoneNumberList/PhoneNumberListMaster';

const ITDashboard = () => {
  const isLogin = localStorage.getItem('loggedAdminIT');
  const userRole = localStorage.getItem('role');
  const userData = JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  // IT context
  const dispatch = useITDispatch();
  const state = useITState();
  // IT states
  const key = state.key;
  const errorMessage = state.failedMessage;
  const sucsessMessage = state.successMessage;
  // logout 
  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  const activeTab = (k) => {
    dispatch({ type: `SET_KEY_TAB_OPEN`, payload: k });
  };
  const handleShowCreateModal = (what) => {
      dispatch({ type: `SET_MODAL_CREATE_${what}`, payload: true });
  };
  const handleShowAccountModal = (what) => {
    dispatch({ type: `SET_MODAL_ACCOUNT_${what}`, payload: true });
    dispatch({ type: `SET_VALUE_ACCOUNT_IT`, payload: userData[0] })
};

  useEffect(() => {
    dispatch({ type: `SET_KEY_TAB_OPEN`, payload: 'ALLUSERS' });
    const isAllowed = itDashboardAllowed.includes(userRole);
    if (!isLogin && !isAllowed) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ProductDashboard">
      <div className="sales-container d-flex align-items-center justify-content-between">
        <div>
          <p>Login User: {userData ? userData[0].fullname : null} </p>
          <p>Role : {userData ? userData[0].role : null} </p>          
        </div>
        <DropdownButton variant='light' title="Setting">
          <Dropdown.Item onClick={() => handleShowAccountModal('IT')}>My account</Dropdown.Item>
          <Dropdown.Item onClick={() => handleShowCreateModal('ALLUSERS')}>Create account</Dropdown.Item>
          <Dropdown.Item onClick={() => handleLogout()}>Log out</Dropdown.Item>
        </DropdownButton>
      </div>
      
      <AllUsersProvider>
          <Tabs
            activeKey={key}
            onSelect={(k) => activeTab(k)}
            fill
            justify
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="ALLUSERS" title="ALL">
              <AllUsersMaster />
            </Tab>
            <Tab eventKey="SALESUSERS" title="Sales">
              <SalesUsersProvider>
                <UserRegisteredSales/>
              </SalesUsersProvider>
            </Tab>
            <Tab eventKey="CMSUSERS" title="CMS">
              <CMSUsersProvider>
                <UserRegisteredCMS/>
              </CMSUsersProvider>
            </Tab>
            <Tab eventKey="ITUSERS" title="IT">
              <ITUsersProvider>
                <UserRegisteredIT/>
              </ITUsersProvider>
            </Tab>
            <Tab eventKey="PHONENUMBERLIST" title="List Phone Number">
              <PhoneNumberListProvider>
                <PhoneNumberList/>
              </PhoneNumberListProvider>
            </Tab>
          </Tabs>
      </AllUsersProvider>
      <ModalComp
        show={state.showModalAccount}
        onHide={() =>
          dispatch({ type: `SET_MODAL_ACCOUNT_IT`, payload: false })
        }
        ModalHeader={'My Account'}
        buttonText={{ primary: 'update' }}
      >
        <BodyModalAccount />
      </ModalComp>
      <ModalComp
        show={state.showModalFailed}
        onHide={() => dispatch({ type: `SET_MODAL_FAILED`, payload: false })}
        ModalHeader={'sorry'}
        buttonText={{ primary: 'close' }}
      >
        {errorMessage ? errorMessage : ' something when wrong, try again later'}
      </ModalComp>
      <ModalComp
        onHide={() => {
          dispatch({ type: `SET_MODAL_SUCCESS`, payload: false })  
        }}
        show={state.showModalSuccess}
        ModalHeader={'updated!'}
        buttonText={{ primary: 'close' }}
      >
        {sucsessMessage ? sucsessMessage : 'successfully updated!'}
      </ModalComp>
    </div>
  );
};

export default ITDashboard;
