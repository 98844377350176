import { createContext, useReducer, useContext } from 'react';

// create context/ ganti nama sesuai context yang sedang dibuat
const CMSUsersStateContext = createContext();
const CMSUsersDispatchContext = createContext();
// state names / ganti dengan nama penanda dari states yang dibutuhkan
const stateGroup = 'CMSUSERS'
// Create a reducer function
const CMSUsersReducer = (state, action) => {
  switch (action.type) {
    case `SET_DATA_${stateGroup}`:
      return { ...state, data: action.payload };
    case `SET_LOADING_${stateGroup}`:
      return { ...state, loading: action.payload };
    case `SET_MODAL_EDIT_${stateGroup}`:
      return { ...state, showModalEdit: action.payload };
    case `SET_VALUE_EDIT_${stateGroup}`:
      return { ...state, valueEdit: action.payload };
    default:
      return state;
  }
};

// Create a provider component
const CMSUsersProvider = ({ children }) => {
  const initialState = {
    key: '',
    data: [],
    loading: true,
    showModalEdit: false,
    valueEdit: {},
  };
  const [state, dispatch] = useReducer(CMSUsersReducer, initialState);

  return (
    <CMSUsersStateContext.Provider value={state}>
      <CMSUsersDispatchContext.Provider value={dispatch}>
        {children}
      </CMSUsersDispatchContext.Provider>
    </CMSUsersStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state / ganti nama sesuai kebutuhan
const useCMSUsersState = () => {
  const context = useContext(CMSUsersStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch / ganti nama sesuai kebutuhan
const useCMSUsersDispatch = () => {
  const context = useContext(CMSUsersDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};
// ganti nama export susai function di atas
export { CMSUsersProvider, useCMSUsersState, useCMSUsersDispatch };
