import React from 'react';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import ButtonComp from '../../../components/button/ButtonComp';
// global state
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// component state
import { useTicketDispatch, useTicketState } from './ProviderTicket';
import {
  getAllTickets,
  dispatchWithTimeout,
} from '../TicketMaster/ActionDispatch';

const BodyModalDelete = ({ value }) => {
  // global state
  const globalState = useCSState();
  const globaldispatch = useCSDispatch();
  const key = globalState.key;
  // component state
  const dispatch = useTicketDispatch();
  const state = useTicketState();
  const stateValue = state.valueDelete;

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const url = `machines/`;
      const rahasia = process.env.REACT_APP_RAHASIA;
      const decSecret = window.btoa(rahasia);
      const response = await axios({
        method: 'delete',
        url: `${baseUrl}/${url}`,
        data: {
          companyId: stateValue.companyId,
          unitId: stateValue.unitId,
        },
        headers: { Authorization: decSecret },
      })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e.response;
        });
      if (response.status === 201) {
        dispatch(getAllTickets(dispatch));
        dispatchWithTimeout(dispatch, `SET_MODAL_DELETE_${key}`, false, 500);
        dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
        return;
      }
      globaldispatch({ type: `SET_MODAL_FAILED`, payload: true });
      return;
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <div className="button-on-form">
      <div> unit id: {value.unitId}</div>
      <div> machine name: {value.machineName}</div>
      <div> company id: {value.companyId}</div>
      <ButtonComp clickFunc={handleDelete} buttonType={'button'} third>
        delete
      </ButtonComp>
    </div>
  );
};

export default BodyModalDelete;
