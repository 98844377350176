import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../components/button/ButtonComp';
import axios from 'axios';
import { baseUrl } from '../../service/baseUrl';
// global states
import { useITState, useITDispatch } from '../../pages/AdminIT/ITDashboard/ProviderIT';
import { dispatchWithTimeout } from '../../pages/AdminIT/ITDashboard/ActionDispatch';

import { useAllUsersDispatch } from '../../pages/AdminIT/AllUserMaster/ProviderAllUsers';
import { getAllAllUsers } from '../../pages/AdminIT/AllUserMaster/ActionDispatch';

import RenderForm from '../../components/RenderForm/RenderForm';

const BodyModalAccount = () => {
  const [imagePreview] = useState(null);
  const [error] = useState(null);
  const [validated, setValidated] = useState(false);
  const [initialUserData, setInitialUserData] = useState({});
  // global state
  const state = useITState();
  const dispatch = useITDispatch();
  const key = state.key;
  const accountDataValue = state.valueAccount;

// alluser states
const allUserDispatch = useAllUsersDispatch();

  const userID = localStorage.getItem('userID')
  const listColumn = [
    {
      id: 1,
      label: '* Name',
      value: 'fullname',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 2,
      label: 'username',
      value: 'userName',
      type: 'text',
      required: true,
      readOnly: false,
    },
    {
      id: 3,
      label: 'password',
      value: 'password',
      type: 'text',
      required: false,
      readOnly: false,
    },
    {
      id: 4,
      label: 'email',
      value: 'email',
      type: 'text',
      required: true,
      readOnly: false,
    },
  ];
  const fetchDataUser = async ()=>{
    const url = `userapp/getonewhere`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decsecret = window.btoa(rahasia);
    const response = await axios({
      method: 'post',
      url: `${baseUrl}/${url}`,
      data: {where:{
        userID: userID, 
      }},
      headers: { Authorization: decsecret },
    })
      .then((response) => {
        console.log('response in fetch user data',response)
        setInitialUserData(response.data.data);
        return response;
      })
      .catch((e) => {
        return e.response;
      });
  }
  const setFormValue = (e, index, column) => {
    const copyObj = Object.assign({}, accountDataValue, {
      [column]: e.target.value,
    });
    dispatch({
      type: `SET_VALUE_ACCOUNT_IT`,
      payload: copyObj,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        for (const key in accountDataValue) {
          const value = accountDataValue[key];
          if (value !== undefined) {
            formData[key] = value;
          }
        }

        const url = `userapp/`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decsecret = window.btoa(rahasia);
        const response = await axios({
          method: 'patch',
          url: `${baseUrl}/${url}`,
          data: {
            userID: userID, 
            userName: formData.userName, 
            password: formData.password, 
            fullname: formData.fullname, 
            email: formData.email, 
            phoneNumber: formData.phoneNumber, 
          },
          headers: { Authorization: decsecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 201) {
          console.log('edit account modal',response.data.data)
          const dataUser = JSON.stringify([
            response.data.data,
          ]);
          dispatch(getAllAllUsers(allUserDispatch));
          localStorage.setItem('userData', dataUser);
          dispatchWithTimeout(dispatch, `SET_MODAL_ACCOUNT_IT`, false, 500);
          dispatchWithTimeout(dispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        dispatch({ type: `SET_MODAL_FAILED_${key}`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };
  useEffect(()=>{
    fetchDataUser();
  },[])

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="form-container px-2 px-4"
      >
        {listColumn.map((column, index) => {
          const inputValue = initialUserData[column.value] || '';
          return (
            <RenderForm
              keyValue={column.id}
              key={column.id}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              errorFile={error}
              imagePreview={imagePreview}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Update Data</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};

export default BodyModalAccount;
