import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Button, Card, Dropdown, Form } from 'react-bootstrap';
import { useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../service/baseUrl';
import ModalComp from '../modal/ModalComp';
import { userRoles } from '../../utils/listRole';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ChartSales = ({ salesData, setSalesData, setTotalCustomerPerSales }) => {
  const [areaFilter, setAreaFilter] = useState("");
  const [showModalFailed, setShowModalFailed] = useState(false);
  const [customers, setCustomers] = useState([]);
  // const [salesData, setSalesData] = useState([]);
  const [totalUsersPerSales, setTotalUserPerSales] = useState([]);
  const bulan = salesData.map(({ fullname }) => fullname);
  const currentUser = localStorage.getItem('salesname');
  const dataBulan = {
    labels: bulan,
    datasets: [{
      label: 'Total customer per sales',
      data: totalUsersPerSales,
      backgroundColor: '#3366dd',
      border: 0,
      borderColor: 'transparent',
      indexAxis: 'x'
    }]
  }

  // const labels = Data.filter((data) => {
  //   return data.area === areaFilter
  // }).map((data) => data.user)
  // const data = {
  //   labels: labels,
  //   datasets: [{
  //     label: 'Total per sales',
  //     data: Data.filter((data) => {
  //       return data.area === areaFilter
  //     }).map((data) => data.total),
  //     backgroundColor: '#3366dd',
  //     border: 0,
  //     borderColor: 'transparent',
  //     indexAxis: 'y'
  //   }]
  // }
  const getAllUserAllSales = async () => {
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .get(`${baseUrl}/user`, { headers: { Authorization: decSecret } })
      .catch((e) => {
        setShowModalFailed(true);
      });
    return response.data.data.users;
  };
  const getSales = async (dispatch) => {
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const url = 'userapp/getwhere';
    const options = { where: { role: userRoles.role3 } };
    const response = await axios({
      method: 'post',
      url: `${baseUrl}/${url}`,
      data: options,
      headers: { Authorization: decSecret },
    }).catch((e) => {
      setShowModalFailed(true);
    });
    if (response.status === 200) {
      let salesResultNames = response.data.data.filter((value) => {
        if (value.fullname === currentUser) {
          return false;
        }
        if (areaFilter === "") {
          return value;
        } else {
          return value.area === areaFilter
        }
      }).map((sales) => {
        const fullname = sales.fullname;
        const salesId = sales.salesId;
        return { fullname, salesId }
      })
      // salesResultNames.unshift({ fullname: 'total', salesId: '@99' })
      setSalesData(salesResultNames)
      return salesResultNames
    }
  };
  const fetchAllData = async () => {
    const users = await getAllUserAllSales();
    const resultSalesNames = await getSales();
    let objResult = {};
    let totalCustomerObj = {total: []}
    resultSalesNames.map((sales) => {
      // if (sales.fullname === 'total') {
        // if (areaFilter !== '') {
        //   totalCustomerObj['total'] = [];
        // }
        // return null;
      // }
      const result = users.filter((user) => {
        return user.currentSales === sales.salesId
      });
      const name = sales.fullname;
      objResult[name] = [...result];
      if (areaFilter !== '') {
      totalCustomerObj['total'] = [...totalCustomerObj['total'], ...result];
      return null;
      }
      totalCustomerObj['total'] = [ ...totalCustomerObj['total'], ...result];
      return null;
    });
    const totalPerSales = resultSalesNames.map((sales) => {
      return objResult[sales.fullname].length
    });
    setCustomers(totalCustomerObj);
    setTotalCustomerPerSales(objResult);
    setTotalUserPerSales(totalPerSales);
  }
  useEffect(() => {
    fetchAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaFilter])
  return (
    <>
      {/* <div className='pb-3'>
      <Bar data={dataBulan} />
    </div> */}
      <div className='select-locations'>
        <strong>Location</strong>
        <Form.Select aria-label="Default select example"
          onChange={(e) => {
            setAreaFilter(e.target.value)
          }}
        >
          <option value=''>All locations</option>
          <option value='JAKARTA'>Jakarta</option>
          <option value='SURABAYA'>Surabaya</option>
          <option value='MEDAN'>Medan</option>
          <option value='CIKARANG'>Cikarang</option>
          <option value='BANDUNG'>Bandung</option>
          <option value='SEMARANG'>Semarang</option>
          <option value='MAKASAR'>Makasar</option>
        </Form.Select>
      </div>
      <div className='row'>        
        <div className='col-6'>
          <Card bg='primary' text='white' className='width-card'>
            <Card.Body>
              <Card.Text>
                <span className='total'>
                  {salesData.length}
                </span>
              </Card.Text>
              <Card.Title><center>Total sales</center></Card.Title>
            </Card.Body>
          </Card>
        </div>
        <div className='col-6'>
          <Card bg='primary' text='white' className='width-card'>
            <Card.Body>
              <Card.Text>
                <span className='total'>
                  {customers['total']?.length ? customers['total']?.length : 0}
                </span>
              </Card.Text>
              <Card.Title><center>Total customer</center></Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className='pt-3'>
        <div className='border rounded-2 p-2'>
          <Bar data={dataBulan} />
        </div>
      </div>

      <ModalComp
        show={showModalFailed}
        onHide={() => setShowModalFailed(false)}
        ModalHeader={'sorry'}
        buttonText={{ primary: 'close' }}
        primaryFunc={() => setShowModalFailed(false)}
      >
        something when wrong, try again later
      </ModalComp>
    </>
  );
};
export default ChartSales;