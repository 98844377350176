import { useEffect, useState } from 'react';
// global states
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// ticket states
import { useCompanyState, useCompanyDispatch } from './ProviderCompany';
import { getAllCompany } from './ActionDispatch';

import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import ModalComp from '../../../components/modal/ModalComp';
import BodyModalCreate from './BodyModalCreate';
import BodyModalEdit from './BodyModalEdit';
import BodyModalDelete from './BodyModalDelete';
import { BiEdit, BiTrash } from "react-icons/bi";

const CompanyMaster = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // global state
  const globalState = useCSState();
  const globalDispatch = useCSDispatch();
  const key = globalState.key;
  // ticket states
  const state = useCompanyState();
  const dispatch = useCompanyDispatch();
  const loading = state.loading;
  const data = state.data;

  const fetchData = async () => {
    dispatch(getAllCompany(dispatch));
  };

  const tableColumn = [
    {
      value: 'companyId',
      label: 'ID',
      type: 'string',
      search: true,
    },
    {
      value: 'companyName',
      label: 'Company Name',
      type: 'string',
      search: true,
    },
    {
      value: 'action',
      label: 'Action',
      type: 'button',
      search: false,
      renderButton: [
        { 
          type: 'edit',
          name: [<BiEdit />],
          handler: (e) => {
            dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_EDIT_${key}`, payload: e });
          },
        },
        {
          type: 'hapus',
          name: [<BiTrash />],
          handler: (e) => {
            dispatch({ type: `SET_MODAL_DELETE_${key}`, payload: true });
            dispatch({ type: `SET_VALUE_DELETE_${key}`, payload: e });
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="search_wrap mt-4 py-2">
        <input
          type="text"
          placeholder="Search Here"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>

      <MainTableCustom
        header={tableColumn}
        data={data}
        loading={loading}
        searchTerm={searchTerm}
      />

      <ModalComp
        show={globalState.showModalCreateCompany}
        onHide={() =>
          globalDispatch({ type: `SET_MODAL_CREATE_${key}`, payload: false })
        }
        ModalHeader={'Company data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalCreate />
      </ModalComp>

      <ModalComp
        show={state.showModalEdit}
        onHide={() =>
          dispatch({ type: `SET_MODAL_EDIT_${key}`, payload: false })
        }
        ModalHeader={'Company Data:'}
        buttonText={{ primary: 'Create' }}
      >
        <BodyModalEdit />
      </ModalComp>

      <ModalComp
        show={state.showModalDelete}
        ModalHeader={`Confirm Delete: ${state.valueDelete.companyName}`}
        onHide={() =>
          dispatch({ type: `SET_MODAL_DELETE_${key}`, payload: false })
        }
      >
        <BodyModalDelete />
      </ModalComp>
    </div>
  );
};

export default CompanyMaster;
