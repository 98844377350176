import { baseUrl } from "./baseUrl";
import axios from "axios";

// const JALURTEST = baseUrl + "testbro";

//update user data api
export const updateUserData = async (form, phoneNum) => {
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const response = await axios
    .patch(`${baseUrl}/user/${phoneNum}`, form, {
      headers: { Authorization: decSecret },
    })
    .catch((e) => {
      return e.response;
    });
  return response;
};

//adding customer by create
export const addUserApi = async (data, salesId) => {
  const makeidNumChar = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  data.userId = makeidNumChar(4);
  data.userSales = salesId;
  data.currentSales = salesId;
  data.typeSubmit = "create";
  const response = await axios
    .post(`${baseUrl}/user/ `, data, {
      headers: { Authorization: decSecret },
    })
    .catch((e) => {
      return e.response;
    });
  return response;
};

//get all user for sales master
// export const getAllUserAllSales = async () => {
//   const response = await axios.get(`${baseUrl}/user`).catch((e) => {
//     setShowModalFailed(true);
//   });
//   if (response.status === 201) {
//     setShowModalUpdateForm(false);
//   }
//   setCustomerCount(response.data.total);
//   setUserDataSales(response.data.data.users);
//   return response;
// };

// //get all user for master with query
// const getAllUserAllSalesWithQuery = async (query) => {
//   setLoading(true);
//   const response = await axios
//     .get(`${baseUrl}/user`, {
//       params: query,
//     })
//     .catch((e) => {
//       setShowModalFailed(true);
//     });
//   setLoading(false);
//   setCustomerCount(response.data.total);
//   setUserDataSales(response.data.data.users);
//   return response;
// };

// //get customer from one sales
// export const getUserDataSales = async (salesId) => {
//   setLoading(true);
//   const response = await axios
//     .get(`${baseUrl}/user/get-specific-sales-user-null/${salesId}`)
//     .catch((e) => {
//       setShowModalFailed(true);
//     });
//   setLoading(false);
//   setUserDataSales(response.data.data.user);
// };
// //get customer from one sales with query
// export const getUserDataWithQuery = async (query) => {
//   setLoading(true);
//   const response = await axios
//     .get(`${baseUrl}/user/get-specific-sales-user-null/${salesId}`, {
//       params: query,
//     })
//     .catch((e) => {
//       setShowModalFailed(true);
//     });
//   setLoading(false);
//   setUserDataSales(response.data.data.user);
//   return response;
// };
// //handle transfer cutomer to other sales
// export const handleTransfer = async (phoneNum, curSales, reason) => {
//   const form = {
//     isTransfer: true,
//     currentSales: curSales,
//     reasonTransfer: reason,
//   };
//   const response = await axios
//     .patch(`${baseUrl}/user/${phoneNum}`, form)
//     .catch((e) => {
//       setShowModalFailed(true);
//     });
//   if (response.status === 201) {
//     setShowModalTransfer(false);
//     setInitialDataUser({});
//     setCurrentSalesId("");
//     setReasonForTransfer("");
//     setShowModalSuccess(true);
//   }
// };

// //get sales today count
// export const getTodayCount = async () => {
//   const response = await axios
//     .get(`${baseUrl}/user/get-total-sales-today/${salesId}`)
//     .catch((e) => {
//       setShowModalFailed(true);
//     });
//   setCustomerCount(response.data.total);
// };
