import React from "react";
import "./header.scss";
import logo from "../../assets/markindo-logo.png";

function Header() {
  return (
    <div className="Header">
      <img src={logo} alt={"logo pt markindo"} />
    </div>
  );
}

export default Header;
