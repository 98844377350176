import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';

const getAllProduct = async (dispatch, whichTechnology) => {
  dispatch({ type: 'SET_LOADING_PRODUCT', payload: true });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'v1/technology/technlogy-master';
  let paramsData = {};
  if(whichTechnology !== null){
    paramsData = {typeTechnology: whichTechnology};
  }else{
    paramsData = {};
  }
  const response = await axios
    .get(`${baseUrl}/${url}`, {
      headers: { Authorization: decSecret },
      params: paramsData
    })
    .catch((e) => {
      dispatch({ type: 'SET_DATA_PRODUCT', payload: [] });
      console.log(e);
    });

  dispatch({ type: 'SET_DATA_PRODUCT', payload: response?.data.data });
  setTimeout(() => {
    dispatch({ type: 'SET_LOADING_PRODUCT', payload: false });
  }, [1500]);
};

const getAllSubProduct = async (dispatch, whichTechnology) => {
  dispatch({ type: 'SET_LOADING_SUB_PRODUCT', payload: true });
  dispatch({ type: 'SET_DATA_SUB_PRODUCT', payload: [] });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'v1/technology/technlogy-subproduct-master';
  let paramsData = {};
  if(whichTechnology !== null){
    paramsData = {typeTechnology: whichTechnology};
  }else{
    paramsData = {};
  }
  const response = await axios
    .get(`${baseUrl}/${url}`, {
      headers: { Authorization: decSecret },
      params: paramsData
    })
    .catch((e) => {
      dispatch({ type: 'SET_DATA_SUB_PRODUCT', payload: [] });
      console.log(e);
    });
  dispatch({ type: 'SET_DATA_SUB_PRODUCT', payload: response?.data.data });
  setTimeout(() => {
    dispatch({ type: 'SET_LOADING_SUB_PRODUCT', payload: false });
  }, [1500]);
};
const getAllSubProductHidden = async (dispatch, whichTechnology) => {
  dispatch({ type: 'SET_LOADING_SUB_PRODUCT', payload: true });
  dispatch({ type: 'SET_DATA_SUB_PRODUCT_HIDDEN', payload: [] });
  const rahasia = process.env.REACT_APP_RAHASIA;
  const decSecret = window.btoa(rahasia);
  const url = 'v1/technology/technlogy-subproduct-master/hidden';
  let paramsData = {};
  if(whichTechnology !== null){
    paramsData = {typeTechnology: whichTechnology};
  }else{
    paramsData = {};
  }
  const response = await axios
    .get(`${baseUrl}/${url}`, {
      headers: { Authorization: decSecret },
      params: paramsData
    })
    .catch((e) => {
      dispatch({ type: 'SET_DATA_SUB_PRODUCT_HIDDEN', payload: [] });
      console.log(e);
    });
  dispatch({ type: 'SET_DATA_SUB_PRODUCT_HIDDEN', payload: response?.data.data });
  setTimeout(() => {
    dispatch({ type: 'SET_LOADING_SUB_PRODUCT', payload: false });
  }, [1500]);
};
function dispatchWithTimeout(dispatch, actionType, payload, delay) {
  setTimeout(() => {
    dispatch({ type: actionType, payload });
  }, delay);
}

export { getAllProduct, dispatchWithTimeout, getAllSubProduct, getAllSubProductHidden };
