import React from 'react';
import axios from 'axios';
import { baseUrl } from '../../../service/baseUrl';
import ButtonComp from '../../../components/button/ButtonComp';
// global state
import { useITState, useITDispatch } from '../ITDashboard/ProviderIT';
// component state
import { useAllUsersDispatch, useAllUsersState } from './ProviderAllUsers';
import { getAllAllUsers, dispatchWithTimeout } from './ActionDispatch';

const BodyModalDelete = () => {
  // global state
  const globalState = useITState();
  const globaldispatch = useITDispatch();
  const key = globalState.key;
  // component state
  const dispatch = useAllUsersDispatch();
  const state = useAllUsersState();
  const deleteValue = state.valueDelete;

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const url = `userapp/`;
      const rahasia = process.env.REACT_APP_RAHASIA;
      const decsecret = window.btoa(rahasia);
      const response = await axios({
        method: 'delete',
        url: `${baseUrl}/${url}`,
        data: {
          userID: deleteValue.userID,
        },
        headers: { Authorization: decsecret },
      })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e.response;
        });
      if (response.status === 201) {
        dispatch(getAllAllUsers(dispatch));
        dispatchWithTimeout(dispatch, `SET_MODAL_DELETE_${key}`, false, 500);
        dispatchWithTimeout(
          globaldispatch,
          `SET_MODAL_SUCCESS_${key}`,
          true,
          500
        );
        return;
      }
      globaldispatch({ type: `SET_MODAL_FAILED_${key}`, payload: true });
      return;
    } catch (e) {
      console.log(e, 'catch error handle submit');
    }
  };

  return (
    <div className="button-on-form">
      <ButtonComp clickFunc={handleDelete} buttonType={'button'} third>
        delete
      </ButtonComp>
    </div>
  );
};

export default BodyModalDelete;
