import React, { useEffect, useState } from 'react';
import MainTableCustom from '../../../components/TableComp/MainTableCustom';
import {
  useProductDispatch,
  useProductState,
} from '../ProductDashboard/ProviderProduct';
import { dispatchWithTimeout, getAllSubProduct, getAllSubProductHidden } from '../ProductDashboard/ActionDispatch';
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import axios from "axios";
import { baseUrl } from "../../../service/baseUrl";


const ProductHidden = () => {
  const [searchTerm] = useState('');
  const [display, setDisplay] = useState(true)
  const state = useProductState();
  const dispatch = useProductDispatch();
  const dataProduct = state.dataSubProductHidden;
  const [filteredProduct, setFilteredProduct] = useState();
  const approle = localStorage.getItem('approle');
  const fetchDataSub = async () => {
    const options = approle ?? null;
    await dispatch(getAllSubProductHidden(dispatch, options));
    await dispatch(getAllSubProduct(dispatch, options));
  };
  const loading = state.loadingSubProduct;
  const handleUpdateHidden = async (id) => {
    const data = {hidden: false, id};
    const url = `v1/technology/technlogy-subproduct-master/hidden`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const decSecret = window.btoa(rahasia);
    const response = await axios
      .post(`${baseUrl}/${url}`, data, {
        headers: { Authorization: decSecret },
      })
      .then((response) => {
        fetchDataSub();
        return response;
      })
      .catch((e) => {
        return e.response;
      });
    if (response.status === 200) {
      setFilteredProduct();
      dispatchWithTimeout(
        dispatch,
        "SET_MODAL_EDIT_TECHNOLOGY",
        false,
        500
      );
      return;
    }
    dispatch({ type: "SET_MODAL_FAILED", payload: true });
    return;
  }

  const tableColumn = [
    {
      id: 1,
      value: 'nameProduct',
      label: 'Name Product',
      type: 'string',
      search: true,
    },
    {
      id: 2,
      value: 'imageProduct',
      label: 'Image Product',
      type: 'image',
    },
    {
      id: 3,
      value: 'hidden',
      valueDisplay: display,
      label: 'Display',
      type: 'display',
      handler: (id) => {
        console.log(display, id)
        handleUpdateHidden(id)
      },
    },
  ];

  useEffect(() => {
    fetchDataSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="mt-4 pb-2">
        <MainTableCustom
          header={tableColumn}
          data={filteredProduct ? filteredProduct : dataProduct}
          loading={loading}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
};

export default ProductHidden;
