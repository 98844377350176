import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
// import ListGroup from 'react-bootstrap/ListGroup';
import ButtonComp from '../../components/button/ButtonComp';
import ChartSales from '../../components/chart/ChartSales';
import { useNavigate } from 'react-router-dom';

// function getRandomNumberBetween(min, max) {
//   return Math.floor(Math.random() * (max - min + 1) + min);
// }
// getRandomNumberBetween(20, 50);
// const sales = [
//   {
//     salesId: '@01',
//     salesName: 'ario',
//     totalCountToday: getRandomNumberBetween(3, 6),
//     totalCountYesterday: getRandomNumberBetween(3, 6),
//     totalCount2YesterDay: getRandomNumberBetween(3, 6),
//     totalCount3YesterDay: 7,
//   },
//   {
//     salesId: '@02',
//     salesName: 'yosua',
//     totalCountToday: getRandomNumberBetween(3, 6),
//     totalCountYesterday: getRandomNumberBetween(3, 6),
//     totalCount2YesterDay: getRandomNumberBetween(3, 6),
//     totalCount3YesterDay: 8,
//   },
//   {
//     salesId: '@03',
//     salesName: 'bambang',
//     totalCountToday: getRandomNumberBetween(3, 6),
//     totalCountYesterday: getRandomNumberBetween(3, 6),
//     totalCount2YesterDay: getRandomNumberBetween(3, 6),
//     totalCount3YesterDay: 10,
//   },
// ];

function SalesCustomerCount({ setSalesCountPage }) {
  const [sales, setSales] = useState([]);
  const [totalCustomerPerSales, setTotalCustomerPerSales] = useState({});
  const navigate = useNavigate();
  //   const getDate = (num) => {
  //     let dateObj = new Date();
  //     let month = dateObj.getUTCMonth() + 1; //months from 1-12
  //     let day = dateObj.getUTCDate() - num;
  //     let year = dateObj.getUTCFullYear();
  //     const newDate = day + "/" + month + "/" + year;
  //     return newDate;
  //   };
  return (
    <>
      <div className="ProductDashboard">
        <div className="sales-container">
          <ButtonComp
            clickFunc={() => {
              navigate('/sales');
            }}
            buttonType={'button'}
            third
          >
            Back to dashboard
          </ButtonComp>
        </div>
        
        <div className='sales-chart'>
          <ChartSales salesData={sales} setSalesData={setSales} setTotalCustomerPerSales={setTotalCustomerPerSales} />
        </div>
        
        {/* <div className='sales-card'>
          <Card style={{ width: '18rem' }} bg='primary'  text='white'>
            <Card.Body>
              <Card.Title><center>Total customer</center></Card.Title>
              <Card.Text>
                <span className='total'>
                  {totalCustomerPerSales[sales[0]?.fullname]?.length}
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </div> */}
        <div className='sales-card'>
          <div className='row'>            
            {sales.map((v, idx) => {
              {
                if (v.fullname !== 'total'){
                return (<div key={idx} className='col-xs-1 col-sm-4 col-md-3 col-lg-2 pb-3'>
                  <Card>
                    <Card.Body>
                      {v.fullname === 'total' ? (<Card.Title>Total customer:</Card.Title>) : (<Card.Title>{v.fullname}</Card.Title>)}
                      {v.fullname === 'total' ? null : (<div>Sales No: {v.salesId}</div>)}
                      {totalCustomerPerSales && (
                        <div>
                          Total Customer:   {totalCustomerPerSales[v.fullname].length}
                        </div>)}
                      {/* <div>
                    Total Customer:
                    {v.totalCountToday +
                      v.totalCountYesterday +
                      v.totalCount2YesterDay +
                      v.totalCount3YesterDay}
                  </div> */}
                    </Card.Body>
                    {/* <ListGroup className="list-group-flush">
                  {v.totalCountToday !== 0 && (
                    <ListGroup.Item>
                      12/10/2022 Total count: {v.totalCountToday}
                    </ListGroup.Item>
                  )}
                  {v.totalCountYesterDay !== 0 && (
                    <ListGroup.Item>
                      13/10/2022 Total count: {v.totalCountYesterday}
                    </ListGroup.Item>
                  )}
                  {v.totalCount2YesterDay !== 0 && (
                    <ListGroup.Item>
                      14/10/2022 Total count: {v.totalCount2YesterDay}
                    </ListGroup.Item>
                  )}
                  {v.totalCount3YesterDay !== 0 && (
                    <ListGroup.Item>
                      15/10/2022 Total count: {v.totalCount3YesterDay}
                    </ListGroup.Item>
                  )}
                </ListGroup> */}
                  </Card>
                </div>)
                }

              }
            })}
            
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesCustomerCount;
