import Header from "./components/Header/Header";
import Router from "./router";
import "./styles/App.scss";

function App() {
  // const [isAuth, setIsAuth] = useState(false);
  // const [userLogin, setUserLogin] = useState({});

  // useEffect(() => {
  //   setIsAuth(localStorage.getItem("logged"));
  // }, []);
  return (
    <div className="App">
      {/* <Header /> */}
      <Router />
      {/* {isAuth ? (
        <Router />
      ) : (
        // <RouterProvider router={router} />
        // <SalesDashboard setIsAuth={setIsAuth} userLogin={userLogin} />
        <LoginPage setIsAuth={setIsAuth} setUserLogin={setUserLogin} />
      )} */}
    </div>
  );
}

export default App;
