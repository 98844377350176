import React from "react";
import { ModalFooter, Table } from "react-bootstrap";
import ButtonComp from "../button/ButtonComp";
import "./user-detail-table.scss";

function UserDetailTable({
  userOf,
  initialDataUser,
  setShowModalUserDetail,
  setShowModalUpdateForm,
  setShowModalTransfer,
  salesId,
}) {
  switch (userOf) {
    case 'single-image':
      return;
    default:
      return (
        <>
        <div className="UserDetailTable table-fixed">
        <Table responsive striped bordered hover>
          {Object.keys(initialDataUser)?.map((key, idx) => {
            if (
              key === "createdAt" ||
              key === "id" ||
              key === "updatedAt" ||
              key === "userId" ||
              key === "dateSample" ||
              key === "followUp"
            ) {
              return null;
            }
            return (
              <tbody key={idx}>
                <tr>
                  <td key={idx}>{`${key}:`}</td>
                  {key === "phoneNumber" && (
                    <td>{`+${initialDataUser[key]}`}</td>
                  )}
                  {key !== "phoneNumber" && <td>{initialDataUser[key]}</td>}
                </tr>
              </tbody>
            );
          })}
        </Table>
      </div>
      {initialDataUser.currentSales === salesId ? (
        <ModalFooter>
          <ButtonComp
            clickFunc={() => {
              setShowModalUserDetail(false);
              setShowModalTransfer(true);
            }}
            buttonType={"button"}
            third
          >
            transfer
          </ButtonComp>
          <ButtonComp
            clickFunc={() => {
              setShowModalUserDetail(false);
              setShowModalUpdateForm(true);
            }}
            buttonType={"button"}
            primary
          >
            edit
          </ButtonComp>
        </ModalFooter>
      ) : null}
      </>)}
}

export default UserDetailTable;
