import axios from 'axios';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ButtonCompNew } from '../../../components/button/ButtonComp';
import { baseUrl } from '../../../service/baseUrl';
import RenderForm from '../../../components/RenderForm/RenderForm';
// global state
import { useCSState, useCSDispatch } from '../CSDashboard/ProviderCS';
// component state
import { useTicketDispatch, useTicketState } from './ProviderTicket';
import { getAllTickets, dispatchWithTimeout } from './ActionDispatch';

const BodyModalEdit = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);
  // global state
  const globalState = useCSState();
  const globaldispatch = useCSDispatch();
  const key = globalState.key;
  // component state
  const dispatch = useTicketDispatch();
  const state = useTicketState();
  const editValue = state.valueEdit;

  const listColumn = [
    {
      id: 1,
      label: 'status',
      value: 'statusTicket',
      type: 'select-status',
      required: false,
      defaultSelect: editValue.statusTicket,
      status: ['on progress', 'on going', 'done', 'assigned'],
      funcCallbackTech: (e) => {
        const copyObj = Object.assign({}, editValue, {
          statusTicket: e.target.value,
        });
        dispatch({
          type: `SET_VALUE_EDIT_${key}`,
          payload: copyObj,
        });
      },
    },
    {
      id: 2,
      label: 'mesin',
      value: 'unitId',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 3,
      label: 'Company ID',
      value: 'companyId',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 4,
      label: 'Reported By',
      value: 'reportedBy',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 5,
      label: 'Email',
      value: 'email',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 6,
      label: 'Mobile Phone',
      value: 'mobilePhone',
      type: 'text',
      required: false,
      readOnly: true,
    },
    {
      id: 7,
      label: 'Error Code',
      value: 'errorCode',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 8,
      label: 'category',
      value: 'category',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 9,
      label: 'Current Problem',
      value: 'currentProblem',
      type: 'text',
      required: true,
      readOnly: true,
    },
    {
      id: 10,
      label: 'Machine Condition',
      value: 'machineCondition',
      type: 'text',
      required: true,
      readOnly: true,
    },
  ];

  const setFormValue = (e, index, column) => {
    // Create a copy of the columns
    const copyObj = Object.assign({}, editValue, { [column]: e.target.value });

    dispatch({
      type: `SET_VALUE_EDIT_${key}`,
      payload: copyObj,
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size <= 2 * 1024 * 1024) {
        setImageChange(true);
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
        setError(null);
      } else {
        setError('File size exceeds 2MB.');
        setSelectedFile(null);
        setImagePreview(null);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        const formData = new FormData();
        if (selectedFile) {
          formData.append('attachment', selectedFile);
          formData.append('imageUpdate', imageChange);
        }
        for (const key in editValue) {
          const value = editValue[key];
          if (value !== undefined) {
            formData.append(key, value);
          }
        }
        const url = `tickets/`;
        const rahasia = process.env.REACT_APP_RAHASIA;
        const decSecret = window.btoa(rahasia);
        const response = await axios({
          method: 'patch',
          url: `${baseUrl}/${url}`,
          data: formData,
          headers: { Authorization: decSecret },
        })
          .then((response) => {
            return response;
          })
          .catch((e) => {
            return e.response;
          });
        if (response.status === 201) {
          dispatch(getAllTickets(dispatch));
          dispatchWithTimeout(dispatch, `SET_MODAL_EDIT_${key}`, false, 500);
          dispatchWithTimeout(globaldispatch, `SET_MODAL_SUCCESS`, true, 500);
          return;
        }
        globaldispatch({ type: `SET_MODAL_FAILED`, payload: true });
        return;
      }
    } catch (e) {
      console.log(e, 'catch error handle submit');
      return;
    }
  };

  return (
    <section className="FormContent">
      <Form
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
        className="pe-2"
      >
        {listColumn.map((column, index) => {
          const inputValue = editValue[column.value] || '';
          return (
            <RenderForm
              key={column.value}
              column={column}
              index={index}
              setFormValue={setFormValue}
              inputValue={inputValue}
              errorFile={error}
              imagePreview={imagePreview}
              handleFileChange={handleFileChange}
            />
          );
        })}
        <div className="button-on-form">
          <ButtonCompNew type={'submit'}>Save</ButtonCompNew>
        </div>
      </Form>
    </section>
  );
};
export default BodyModalEdit;
